import React, { useContext, Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import  { ListContext } from '../ListBuilder/ListContext';

import './unitlist.css'
import ListItemUnit from './ListItemUnit';

// this component should just accept a prop with the ID
// probably needs a wrapper component do pass an ID

class UnitList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      units: [],
  };
}

componentDidMount() {
  const { agendaID } = this.props;

  if (agendaID){
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase.units()
    .where('agendaID', '==', agendaID)
    .onSnapshot(snapshot => {
      let units = [];
  
      snapshot.forEach(doc =>
        units.push({ ...doc.data(), uid: doc.id }),
      );  
      this.setState({
        units,
        loading: false,
      });
    });
  }  
};

componentWillUnmount() {
  this.unsubscribe();
}
      
  render() {
    let { units, loading } = this.state;
    let UnitBoxes = [];
    const { selectedFactionID } = this.props;

    if (selectedFactionID && !loading){
      units = units.filter(unit => unit.factionID === selectedFactionID);
    }

    if(units.length != 0){
      UnitBoxes = units.map((unit, i) => ( 
        <ListItemUnit
          key={i}
          id={unit.uid} 
          name={unit.name}
          unit={unit}
          agendaID={unit.agendaID}
          displayAdd
          displayRemove
        />
      ))
    }
    
    return (
      <div className="units-container model-list">
        {
          loading ?
          <p>Loading...</p>
          :
          <React.Fragment>
            { UnitBoxes }
          </React.Fragment>
        }
      </div>
    );
  }
}

UnitList.propTypes = {
  agendaID: PropTypes.string,
  selectedFactionID: PropTypes.string
}

UnitList.defaultProps = {
  agendaID: null,
  selectedFactionID: null
}

export default withFirebase(UnitList);
