import React, { useState, useEffect } from 'react';
import { Message, Icon } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import _ from 'lodash';

import './eventsfeed.css';

function EventsFeed(props) {

  return (
    <div>
      <h3>Upcoming Events</h3>

      <Message color='green' >
      <Message.Header>The BoLS Open - Austin, TX</Message.Header>
        <div>
          <Icon name="calendar alternate outline" />June 12th - 14th
        </div>
        3 Days of Monsterpocalypse! Single Monster, 2 Monster and Upside Down Event!
        <br />
        <a className="bold-link" href="https://tabletop.events/conventions/the-bols-open/schedule/13" target="_blank">
        https://bolsopen.com/
        </a>
      </Message>
      
    </div>
  )
};

export default withFirebase(EventsFeed);