import React, { Component } from 'react';
import PropTypes from 'prop-types';


class DropItems extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.dropItems.map(item =>
            <option key={item.uid} value={item.uid}>{item.name}</option>
        )}
      </React.Fragment>
    );
  }
};

DropItems.propTypes = {
  dropItems: PropTypes.array
}

DropItems.defaultProps = {
  dropItems: null  
}

export default DropItems; 