import React, { useContext, useState } from 'react';
import {Modal, Button, Icon, ButtonGroup, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import AssetCard from '../StatCard/AssetCard';
import  { ListContext } from '../ListBuilder/ListContext';

const ListItemAsset = (props) =>{
  
  const [open, setOpen] = useState(false);
  const {id, name, asset} = props;

  return (
    <div className="generic-list-item">
      <div
        onClick={ () => setOpen(true)}
        key={id}
        className={`asset-box model-list-item bright-hover animated fadeIn fast ${id}`}
      >
        <React.Fragment>
          <div className="item-label">
            <div className="item-name">
                {name}
            </div>
          </div>
        </React.Fragment>
      </div>
      <Modal
        open={open}
        onClose={ () => setOpen(false)}
        closeIcon
        style={{'maxWidth': '700px', 'position': 'static'}}
      >
        <AssetCard asset={asset}/>
      </Modal>
    </div>
  );
};

ListItemAsset.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  asset: PropTypes.object,
  displayAdd: PropTypes.bool,
  displayRemove: PropTypes.bool,
  listCount: PropTypes.number
};

ListItemAsset.defaultProps = {
  id: null,
  name: null,
  asset: {},
  displayAdd: false,
  displayRemove: false,
  listCount: null
};

export default withFirebase(ListItemAsset);