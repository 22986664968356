import React from "react";
import { NavLink } from 'react-router-dom';
import MenuItems from "../Navigation/menu-items"
import AdminMenuItems from "../Navigation/admin-menu-items";
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import './header.css';

export default props => {
  return (
    <div className="main-header">
      <div className="hamburger-spacer" />
      <div className="logo">
        <NavLink className="logo-menu-item" to={ROUTES.LANDING}>
          Monster Room
        </NavLink>
      </div>
      <div className="header-menu">
        <MenuItems class="header-menu-items" />
        <AuthUserContext.Consumer>
          {authUser =>
            authUser &&
            <AdminMenuItems class="header-menu-items"/>
          }
        </AuthUserContext.Consumer>
      </div>
    </div>
  );
};