import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { withFirebase } from '../../Firebase';


function SignOutButton(props){

  function logoutClicked(){
    props.firebase.signOut();
  }

  return(
  <Button
    color="green"
    onClick={logoutClicked}
    content="Sign Out"
  />
  )
}



export default withFirebase(SignOutButton);