import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../../Firebase';

import './cardrule.css'

class CardRule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rule: {},
      loading: false
    };

}

componentDidMount() {
  const { ruleID } = this.props;

  this.setState({ loading: true });

  this.unsubscribe = this.props.firebase.rule(ruleID)
  .onSnapshot(snapshot => {

    this.setState({
      rule: snapshot.data(),
      loading: false,
    });

  });
}

componentWillUnmount() {
  this.unsubscribe();
}
      
  render() {
    const {loading, rule} = this.state;

    if(!rule || loading){
      return <div className="card-rule-box">{loading ? 'Loading...' : 'Rule Not Found'}</div>;
    } else{
      return (
        <div className="card-rule-box">
          <span className="rule-name">{rule.name} -</span> 
          {rule.text}
        </div>
      );
    }
  }
}

CardRule.propTypes = {
  ruleID: PropTypes.string.isRequired
}

CardRule.defaultProps = {
}

export default withFirebase(CardRule);
