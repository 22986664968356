import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';

import StatBlock from './StatBlock';
import AttackSection from './AttackSection';
import RulesSection from './RulesSection';
import UnitStats from './StatBlock/UnitStats';

import './statcard.css'

class UnitCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      factionDisplay: '',
      loading: false
  };
}

componentDidMount() {
  const factionID = this.props.unit.factionID;

  this.setState({ loading: true });

  this.unsubscribe = this.props.firebase.faction(factionID)
  .onSnapshot(snapshot => {
    //just grabbing name
    const faction = snapshot.data();

    this.setState({
      factionDisplay: faction.name,
      loading: false,
    });
  });
}

componentWillUnmount() {
  this.unsubscribe();
}


  render() {
    // todo
    // for units add elite symbol?

    let {unit} = this.props;
    let {form, factionDisplay} = this.state;

    return (
      <div className={`stat-card-container unit-card`}>
        <div className="stat-card-top-row">
          <div className="stat-name-row">
            <div className="model-name">{unit.name}</div>
            <div className="model-subtitle">{factionDisplay} Unit</div>
          </div>
          
          <StatBlock
            spd={unit.spd}
            def={unit.def}
            highMobility={unit.highMobility}
          />

        </div>
        
        <RulesSection rulesById={unit.rulesById} />
        <AttackSection attacks={unit.attacks} />
        <UnitStats cost={unit.cost} elite={unit.elite}/>
      </div>
    );
  }
}

UnitCard.propTypes = {
  unit: PropTypes.object
}

UnitCard.defaultProps = {
  unit: {}
}

export default withFirebase(UnitCard);
