// import _ from 'lodash';

// def not needed for calc
export function CalcDefHit(def, white, blue, red, reroll) {
  var white_odds = [3, 2, 1]
  var blue_odds = [2, 3, 1]
  var red_odds = [1, 4, 1]
  var all_color_odds = [white_odds, blue_odds, red_odds]

  var success_odds = calculate_spread(white, blue, red, all_color_odds);
  var cumulative_odds = convert_to_cumulative(success_odds);
  var reroll_odds = cumulative_odds;
  if(reroll){
    for(var i=0; i<reroll_odds.length; i++){
      reroll_odds[i] = with_reroll(reroll_odds[i]);
    }
  }

  var formatted_output = [];

  for(var i=0; i<reroll_odds.length; i++){
    formatted_output.push([i, (100*reroll_odds[i]).toFixed(2)]);
  }
  // array of all possible values
  return formatted_output;
}

function calculate_spread(white, blue, red, all_color_odds){
  if(white === undefined){
    white = 0;
  }
  if(blue === undefined){
    blue = 0;
  }
  if(red === undefined){
    red = 0;
  }
  
  let old_odds = [1];
  let new_odds = [];
  let all_dice_nums = [white, blue, red];
  for(var color=0; color < all_dice_nums.length; color++){
    let num_dice = all_dice_nums[color];
    let dice_odds = [];
    for(var possible_result=0; possible_result<all_color_odds[color].length; possible_result++){
      dice_odds.push(all_color_odds[color][possible_result]/6.0);
    }
    for(var die = 0; die < num_dice; die++){
      new_odds = [];
      for(var x=0; x < old_odds.length+2; x++){
        new_odds.push(0);
      }
      for(var new_successes=0; new_successes<dice_odds.length; new_successes++){
        let new_success_odds = dice_odds[new_successes];
        for(var current_successes=0; current_successes<old_odds.length; current_successes++){
          new_odds[current_successes+new_successes] += old_odds[current_successes] * new_success_odds
        }
      }
      old_odds = new_odds;
    }
  }
  return new_odds;
}

function convert_to_cumulative(spread){
  let result = [];
  let cumulative = 0;
  for(var i=0; i < spread.length; i++){
    result.push(1-cumulative);
    cumulative += spread[i];
  }
  return result;
}

function with_reroll(cumulative_odds){
  return 1-((1-cumulative_odds)*(1-cumulative_odds));
}