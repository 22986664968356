import React, {useContext} from 'react';
import { Helmet } from "react-helmet";
import AgendaCataglog from '../AgendaCatalog';
import { Tab, Label, Menu } from 'semantic-ui-react';
import { ListContext } from "../ListBuilder/ListContext";
import CurrentList from "../ListBuilder/CurrentList";
import useWindowSize from './useWindowSize';
import { getCount } from '../ListBuilder/Util';

import './layout.css';


function AgendaContainer(props) {
  const listContext = useContext(ListContext);
  const { list } = listContext;
  const { isList } = list;

  const size =  useWindowSize();
  const isSmall = size.width < 900;
  
  const showTabs = isList && isSmall;
  let panes = [];

  // for mobile/smaller screens
  if(showTabs){
    panes = [
      { menuItem: {key:'catalog', icon:'book', content: 'Catalog' }, pane: <Tab.Pane><AgendaCataglog {...props}/></Tab.Pane> },
      { menuItem: (
        <Menu.Item key='messages'>
          <span className="tab-title">List</span>
          <Label circular color="blue">{getCount(list.monsters)}</Label>
          <Label circular color="green">{getCount(list.units)}</Label>
          <Label circular color="grey">{getCount(list.buildings)}</Label>
        </Menu.Item>
      ),
      pane: <Tab.Pane><CurrentList /></Tab.Pane> }
    ];

    return(
      <div className="agenda-container small-screen">
        <Helmet>
          <title>Browse Agenda</title>
          <meta name="Monster Room" content="Model rules, list builder and dice calculator for Monsterpocalypse." />
        </Helmet>

        <Tab
          panes={panes}
          renderActiveOnly={false}
          menu={{ className: 'agenda-tab-menu', borderless: true, attached: false, tabular: false, seconddary: true }}
        />
      </div>
    )
  }

  return(
    <div className="agenda-container large-screen">
      <AgendaCataglog {...props}/>
      {
        isList &&
        <CurrentList />
      }
    </div>
  )
}

export default AgendaContainer