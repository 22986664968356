import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Dropdown } from 'semantic-ui-react';
import { withFirebase } from '../../Firebase';
import useForm from '../useForm';
import validate from './SelectRulesValidationRules';

import './selectRulesForm.css';

/* 
  todo it would be great to use Semantic UIs Dropdown with search
  unfortunately the onChange handler is working how I expect, punting for now
*/ 

function SelectRuleForm(props) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(returnSelectedRule, validate);

  const [rules, setRules] = useState([]);

  function returnSelectedRule() {
    const result = {
      uid: rules[values.ruleIndex].uid,
      name: rules[values.ruleIndex].name,
      text: rules[values.ruleIndex].text
    }

    //return result;
    props.onRuleSubmit(result);
  }

  // load on mount, data for dropdown
  useEffect(() => {
    props.firebase.rules()
      .onSnapshot(snapshot => {
        let rules = [];

        snapshot.forEach(doc =>
          rules.push({ ...doc.data(), uid: doc.id }),
        );

        setRules(rules);
      });
  }, []); // empty array as 2nd arg means this will only run on mount

  // const dropdownOptions = rules.map((rule, i) => {
  //   return {key: i, value: i, text: rule.name}
  // });

  return (
    <div className="form-container select-rule-form">
      <Form onSubmit={handleSubmit} noValidate error>
      <label>Select Rule</label>
        <select className="ui search dropdown" name="ruleIndex" value={values.ruleIndex || ''} onChange={handleChange}>
          <option value=''>Select a Rule...</option>
          {rules.map((rule, i) =>
              <option key={i} value={i}>{rule.name}</option>
          )}
        </select>
        {errors.ruleIndex &&
          <Message error content={errors.ruleIndex} />
        }
        
        <div>
          {
            (values.ruleIndex) &&
            <React.Fragment>
            <div>{rules[values.ruleIndex].name}</div>
            <div>{rules[values.ruleIndex].text}</div>
            </React.Fragment>
          }
        </div>
        <Button color="green" type="submit">Add</Button>
      </Form>
      {/*Not working yet, semantic dropdown doesnt fire standard events so useForm needs jank modification*/}
      {/*<Dropdown*/}
      {/*  placeholder='Select Rule'*/}
      {/*  search*/}
      {/*  selection*/}
      {/*  options={dropdownOptions}*/}
      {/*  name="ruleIndex"*/}
      {/*  // value={values.ruleIndex || ''}*/}
      {/*  onChange={handleChange}*/}
      {/*  className={"gobble"}*/}
      {/*/>*/}
    </div>
  );
};

export default withFirebase(SelectRuleForm);

