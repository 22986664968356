import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Dimmer, Loader, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { withFirebase } from '../../Firebase';
import useForm from '../useForm';
import validate from './FactionFormValidationRules';

// TODO form styles, success message and clear form after success
// TODO Let user edit existing docs

function FactionForm(props) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submitFaction, validate);
  // submitFaction is the callback after validate is successful

  const [agendas, setAgendas] = useState([]);
  //if editing prevent uid from changing
  const [editing, setEditing] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [savedSuccess, setSavedSucess] = useState(null);

  function submitFaction() {
    props.firebase.factions().doc(values.uid).set({
      name: values.name,
      agendaID: values.agenda
    })
    .then(function() {
      setSavedSucess(true);
    })
    .catch(function(error){
      console.log("Error saving ", error)
      setSavedSucess(false);
    })
  }
  // load on mount, data for dropdown
  useEffect(() => {
    // if editing
    if ( props.match.params.id ){
      const editID = props.match.params.id;
      setLoading(true);
      setEditing(true);

      props.firebase.faction(editID)
      .onSnapshot(snapshot => {
        const entry = snapshot.data();         
        // set default values
        values.uid = editID;
        values.name = _.get(entry, 'name');
        values.text = _.get(entry, 'text');
        values.agenda = _.get(entry, 'agendaID');
        setLoading(false);
      });
    }

    props.firebase.agendas()
      .onSnapshot(snapshot => {
        let agendas = [];

        snapshot.forEach(doc =>
            agendas.push({ ...doc.data(), uid: doc.id }),
        );

        setAgendas(agendas);
      });
  }, []); // empty array as 2nd arg means this will only run on mount

  return (
    <div className="form-container">
    {
      loading ?
      <Segment>
        <Dimmer active inverted>
          <Loader/>
        </Dimmer>
      </Segment>
      :
      <Form onSubmit={handleSubmit} noValidate error>
        <Message
          hidden={!savedSuccess}
          icon={savedSuccess ? 'check circle' : 'exclamation circle'}
          content={savedSuccess ? 'Save successful' : 'Did not save!'}
        />
        <Button color="green" type="submit" className="button is-block is-info is-fullwidth">Submit Faction</Button>
        <Form.Group>
          <Form.Field>
            <label>Faction Name</label>
              <Form.Input type="text" name="name" onChange={handleChange} value={values.name || ''} required />
              {errors.name &&
                <Message error content={errors.name} />
              }
          </Form.Field>
          <Form.Field>
            <label>UID(usually a slug of name)</label>
              <Form.Input disabled={editing} type="text" name="uid" onChange={handleChange} value={values.uid || ''} required />
              {errors.uid &&
                <Message error content={errors.uid} />
              }
          </Form.Field>
          <Form.Field>
            <label>Agenda</label>
            <select className="ui selection dropdown" value={values.agenda || ''} name="agenda" onChange={handleChange}>
              <option key={0} >Choose Agenda</option>
              {agendas.map(agenda =>
                  <option key={agenda.uid} value={agenda.uid}>{agenda.name}</option>
              )};
            </select>
            {errors.agenda &&
                <Message error content={errors.agenda} />
              }
          </Form.Field> 
        </Form.Group>       
      </Form>
    }
    </div>
  );
};

export default withFirebase(FactionForm);

