import React from "react";
import PropTypes from "prop-types";
import { Label } from 'semantic-ui-react';

import './listnumbers.css';

const ListNumbersDisplay = (props) => {

  return (
    <div className="list-numbers-display">
        <Label className='list-label' color='blue'>
          M
          <Label.Detail>{props.numMonsters}</Label.Detail>
        </Label>
        <Label className='list-label' color='green'>
          U
          <Label.Detail>{props.numUnits}</Label.Detail>
        </Label>
        <Label className='list-label' color='grey'>
          B
          <Label.Detail>{props.numBuildings}</Label.Detail>
        </Label>
    </div>
  )


}

ListNumbersDisplay.propTypes = {
  numMonsters: PropTypes.number,
  numUnits: PropTypes.number,
  numBuildings: PropTypes.number
}

ListNumbersDisplay.defaultProps = {
  numMonsters: '',
  numUnits: '',
  numBuildings: ''
}

export default ListNumbersDisplay;