import countBy from 'lodash/countBy';
import { getCount } from './Util';


export default function validateList(list) {
  // Error Object format
  // {level: 'info/warning/danger', msg: 'Custom Message'}
  let errors = [];

  // MONSTERS
  if (getCount(list.monsters) === 0) {
    errors.push({level: 'warning', msg: 'No Monsters in list'});
  } 
  if (getCount(list.monsters) >= 4) {
    errors.push({level: 'warning', msg: 'This list contains more than 3 Monsters'});
  } 
  if (getCount(list.monsters) === 1 & getCount(list.units) > 15 ) {
    errors.push({level: 'danger', msg: 'A 1 Monster List can only contain 15 units'});
  }
  if (getCount(list.monsters) === 2 & getCount(list.units) > 20 ) {
    errors.push({level: 'danger', msg: 'A 2 Monster List can only contain 20 units'});
  }
  if (getCount(list.monsters) === 3 & getCount(list.units) > 25 ) {
    errors.push({level: 'danger', msg: 'A 3 Monster List can only contain 25 units'});
  }
  
  // UNITS
  if (getCount(list.units) === 0) {
    errors.push({level: 'warning', msg: 'No Units in list'});
  }

  // BUILDINGS
  if(getCount(list.buildings) < 6){
    errors.push({level: 'warning', msg: 'List must contain at least 6 buildings'});
  }
  if(getCount(list.buildings) > 12){
    errors.push({level: 'danger', msg: 'List has more than 12 buildings'});
  }
  // no more than 4 (except apartments)
  if(getCount(list.buildings) >= 4){
    list.buildings.forEach(building => {
      if(building.count > 4 && building.uid !== 'apartment'){
        errors.push({level: 'danger', msg: `${building.name} over limit of 4`});  
      }
    })

  }

  return errors;
};