import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';

import StatBlock from './StatBlock';
import AttackSection from './AttackSection';
import RulesSection from './RulesSection';

import './statcard.css'

class BuildingCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      factionDisplay: '',
      loading: false
  };
}

componentDidMount() {
  // const factionID = this.props.unit.factionID;

  // Buildings dont currently have factions
  // this.setState({ loading: true });

  // this.unsubscribe = this.props.firebase.faction(factionID)
  // .onSnapshot(snapshot => {
  //   //just grabbing name
  //   const faction = snapshot.data();
  //   console.log('faction', faction);

  //   this.setState({
  //     factionDisplay: faction.name,
  //     loading: false,
  //   });
  // });
}

// componentWillUnmount() {
//   this.unsubscribe();
// }


  render() {
    // todo
    let {building} = this.props;
    // let {form, factionDisplay} = this.state;

    return (
      <div className={`stat-card-container building-card`}>
        <div className="stat-card-top-row">
          <div className="stat-name-row">
            <div className="model-name">{building.name}</div>
            <div className="model-subtitle">Building</div>
          </div>
          
          <StatBlock
            def={building.def}
          />

        </div>
        
        <RulesSection rulesById={building.rulesById} />
        <AttackSection attacks={building.attacks} />
        
      </div>
    );
  }
}

BuildingCard.propTypes = {
  building: PropTypes.object
}

BuildingCard.defaultProps = {
  building: {}
}

export default withFirebase(BuildingCard);
