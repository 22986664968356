export default function validate(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Name required';
  } 
  if (!values.uid) {
    errors.uid = 'UID required';
  }
  if (!values.factionID) {
    errors.factionID = 'Faction required';
  }
  if (!values.cost) {
    errors.cost = 'Cost required';
  }
  return errors;
};