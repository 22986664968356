import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import SignOutButton from '../Accounts/Logout'


import * as ROUTES from '../../constants/routes';

// only used for desktop menu currently
class AdminMenuItems extends Component {
  render() {
    const actClass = "active-item";
    return (
      <React.Fragment>
        <NavLink exact activeClassName={actClass} className={this.props.class} to={`${ROUTES.ADMIN}`}>
          Admin
        </NavLink>
        <SignOutButton/>
        
      </React.Fragment>
    );
  }
};

AdminMenuItems.propTypes = {
  class: PropTypes.string
}

AdminMenuItems.defaultProps = {
  class: ''  
}

export default AdminMenuItems; 