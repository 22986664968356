import React, { useContext, useState } from 'react';
import { Modal, Button, Icon, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import UnitCard from '../StatCard/UnitCard';

import  { ListContext } from '../ListBuilder/ListContext';

const ListItemUnit = (props) => {
  const [open, setOpen] = useState(false);   
  const {addUnit, removeUnit, list, getUnitCountById} = useContext(ListContext);

  // listCount is only passed when display in the 'List' not Catalog
  const {id, name, unit, displayAdd, displayRemove, listCount, agendaID} = props;

  // Logic for catalog display
  let showCatalogCount = false;
  let count = getUnitCountById(id);

  if(!listCount && list.isList){
    // count = getUnitCountById(id);
    showCatalogCount = count > 0;
  } else if(listCount >= 2){
    // count = listCount;
    showCatalogCount = true;
  }

  // if list and agenda matches or isn't set yet
  let displayListControls = list.isList && 
    (list.agendaID === agendaID || !list.agendaID );

  // catalog display and list display are similar but slightly different
  // const displayName = listCount >= 2 ? `${listCount}x ${name}` : name;
  const displayName = name;

  return (
    <div className="generic-list-item">
      <div
        onClick={ () => setOpen(true)}
        key={id}
        className={`unit-box model-list-item bright-hover animated fadeIn fast ${id}`}
      >
        <React.Fragment>
          <div className="item-label">

            <div className="item-name">
              {displayName}
            </div>
            {
              showCatalogCount &&
              <Label className="catalog-count-label" color="teal">
                {count}
              </Label>
            }

          </div>
          </React.Fragment>
      </div>
      { displayListControls &&
      <Button.Group className="list-control-btn-grp">
        <Button className="list-control-add-btn" compact size="mini" icon onClick={() => addUnit(unit)}>
          <Icon name='plus' />
        </Button>
        <Button compact size="mini" icon disabled={count <= 0} onClick={() => removeUnit(unit)}>
          <Icon name='minus' />
        </Button>
      </Button.Group>
      }
      <Modal 
        open={open}
        onClose={ () => setOpen(false)}
        closeIcon
        style={{'maxWidth': '700px', 'position': 'static'}}
      >
        <UnitCard unit={unit}/>
      </Modal>
    </div>    
  );
  
}

ListItemUnit.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  unit: PropTypes.object,
  displayAdd: PropTypes.bool,
  displayRemove: PropTypes.bool,
  listCount: PropTypes.number,
  agendaID: PropTypes.string,
}

ListItemUnit.defaultProps = {
  id: null,
  name: null,
  unit: {},
  displayAdd: false,
  displayRemove: false,
  listCount: null,
  agendaID: '',
}

export default withFirebase(ListItemUnit);