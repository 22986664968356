export const NO_CHANCE = [
  "Don't do it!",
  "Please reconsider.",
  "I'd advise agaisnt it.",
  "It's a trap!",
  "You can't be serious.",
  "Living on a prayer."
]

export const BAD_CHANCE = [
  "Never tell me the odds!",
  "Not likely.",
  "Not looking good.",
  "Got any more of them dice?",
]

export const AVG_CHANCE = [
  "Not too bad, not too good.",
  "Kinda like flipping a coin.",
  "Eh, could be worse.",
  "Feeling lucky?",
  "Is this roll important?",
]

export const GOOD_CHANCE = [
  "What could go wrong?",
  "Looking decent.",
  "Pretty good.",
  "I've taken worse odds.",
]

export const DEFINITE_CHANCE = [
  "Do it!",
  "Pull the trigger!",
  "Doesn't get much better.",
  "My kind of odds.",
  "Locked and Loaded.",
  "What are you waiting for?"
]