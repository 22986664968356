import React, { useState, useEffect } from "react";
// importing slide directly, keep load smaller
import Menu from 'react-burger-menu/lib/menus/slide';
import { NavLink } from 'react-router-dom';
import SignOutButton from '../Accounts/Logout'

import {AuthUserContext} from '../Session';
import * as ROUTES from '../../constants/routes';

import './burger-menu.css';

const BurgerMenu = (props) => {
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    setIsClosed(false);
  });

  const actClass = "bm-active-item";
  return (
    <Menu {...props}  menuClassName={ "mobile-menu" } isOpen={ isClosed }>
      <NavLink exact activeClassName={actClass} onClick={() => setIsClosed(true)} to={ROUTES.LANDING}>
        Home
      </NavLink>
      <NavLink exact activeClassName={actClass} onClick={() => setIsClosed(true)} to={`${ROUTES.BROWSE}/destroyers`}>
        Destroyers
      </NavLink>
      <NavLink exact activeClassName={actClass} onClick={() => setIsClosed(true)} to={`${ROUTES.BROWSE}/protectors`}>
        Protectors
      </NavLink>
      <NavLink exact activeClassName={actClass} onClick={() => setIsClosed(true)} to={`${ROUTES.RULES_INDEX}`}>
        Rules
      </NavLink>
      <NavLink exact activeClassName={actClass} onClick={() => setIsClosed(true)} to={`${ROUTES.DICE_ODDS}`}>
        Dice
      </NavLink>
      <AuthUserContext.Consumer>
        {authUser =>
          authUser &&
          <React.Fragment>
            <NavLink exact activeClassName={actClass} onClick={() => setIsClosed(true)} to={`${ROUTES.ADMIN}`}>
              Admin
            </NavLink>
            <SignOutButton/>
          </React.Fragment>
        }
      </AuthUserContext.Consumer>
    </Menu>
  );
};

export default BurgerMenu;