import React, { useState, useEffect } from 'react';
import { Container, Input, Button, Icon } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import _ from 'lodash';
import preventDoubleTapZoom from './PreventDoubleTap';

import './numberinput.css';

// callback onValueChanged
// randomly choosing 30 as max input

function NumberInput(props) {
  const [count, setCount] = useState(0);
  const maxInt = 30;

  useEffect(() => {
    if(count < 0 || !_.isInteger(count)){
      setCount(0);
    }
    if(count > maxInt){
      setCount(maxInt);
    }
    //parent callback
    props.onValueChanged(count);
  });
 
  function handleChange(e){
    let val = (e.target.value >= 0) ? e.target.value : 0;
    setCount(parseInt(val));
  }

  return (
    <div className={`dice-input`}>
      <Button
        color='teal'
        className='input-button no-tap-zoom'
        size='tiny' disabled={count<=0}
        onClick={()=> setCount(count - 1)}
      >
        <Icon name='minus'/>
      </Button>
      <Input className={`input-num ${props.className}`} type='number' onChange={handleChange} type='text' value={count} />
      <Button 
        color='teal'
        className='input-button no-tap-zoom'
        disabled={count > maxInt}
        onClick={()=> setCount(count + 1)}
        // onTouchStart={preventDoubleTapZoom}
        >
          <Icon name='plus'/>
        </Button>
    </div>
  )
};

export default withFirebase(NumberInput);