import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from "uuid";

import CardAttack from '../CardAttack/CardAttack';

import './attackSection.css'

// Receives abilities objects
class AttackSection extends Component {
  constructor(props) {
    super(props);
}

  render() {
    const {attacks} = this.props;
    let displayAttacks = [];

    if(attacks){
      displayAttacks = attacks.map((attack, i) =>
        <CardAttack 
          key = {uuid.v4()}
          type = {attack.type}
          aDice = {attack.aDice}
          bDice = {attack.bDice}
          pDice = {attack.pDice}
          range = {attack.type === 'blast' ? attack.range : null}
          rulesById = {attack.rulesById}
        />
      )
    }

  return (
    <React.Fragment>
      {
        (displayAttacks.length > 0 ) &&
        <div className="attacks">
          {displayAttacks}
        </div>
      }
      </React.Fragment>
    );
  }
}

AttackSection.propTypes = {
  attacks: PropTypes.array
}

AttackSection.defaultProps = {
  attacks: []
}

export default AttackSection;
