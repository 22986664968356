export default function validate(values) {
  let errors = {};
  if (!values.type) {
    errors.type = 'Must Select Type';
  }
  if (values.type === "blast" && !values.range) {
    errors.range = 'Must Enter Range';
  }
  if (!values.actionDice) {
    errors.actionDice = 'Must Enter Number';
  }
  if (!values.boostDice) {
    errors.boostDice = 'Must Enter Number';
  }
  if (!values.powerDice) {
    errors.powerDice = 'Must Enter Number';
  }
  return errors;
};