import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Label, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { withFirebase } from '../../Firebase';
// import {withAuth} from "../../Accounts/withAuth";
import _ from 'lodash';
import useForm from '../useForm';
import validate from './BuildingFormValidationRules';

import ModelDetails from '../ModelDetails';

import './buildingForm.css';

function BuildingForm(props) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submitBuilding, validate);
  // submitMonster is the callback after validate is successful

  //if editing prevent uid from changing
  const [editing, setEditing] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [detailsReady, setDetailsReady] = useState(false);
  const [savedSuccess, setSavedSucess] = useState(null);

  function isDetailsReady(bool){
    setDetailsReady(bool);
  }

  function submitBuilding() {    
    props.firebase.buildings().doc(values.uid).set({
      name: values.name,
      attacks: values.details.attacks,
      rulesById: values.details.rulesById,
      def: values.details.def
    })
    .then(function() {
      setSavedSucess(true);
    })
    .catch(function(error){
      console.log("Error saving ", error)
      setSavedSucess(false);
    })
  }

  useEffect(() => {
    // if editing
    if ( props.match.params.id ){
      const editID = props.match.params.id;
      setLoading(true);
      setEditing(true);
      // user doesnt have to save details
      setDetailsReady(true);

      props.firebase.building(editID)
      .onSnapshot(snapshot => {
        const entry = snapshot.data();         
        // set default values
        values.uid = editID;
        values.name = _.get(entry, 'name');
        // initial details
        values.details = {};
        values.details.def = _.get(entry, 'def');
        values.details.spd = _.get(entry, 'spd');
        values.details.attacks = _.get(entry, 'attacks');
        values.details.rulesById = _.get(entry, 'rulesById');
        values.details.highMobility = _.get(entry, 'highMobility');
        setLoading(false);
      });
    }
  }, []); // empty array as 2nd arg means this will only run on mount

  function onDetailsSubmit(vals){
    values.details = vals;
  }

  return (
    <div className="form-container">
    {
      loading ?
      <Segment>
        <Dimmer active inverted>
          <Loader/>
        </Dimmer>
      </Segment>
      :
      <React.Fragment>
      <Message
        hidden={!savedSuccess}
        icon={savedSuccess ? 'check circle' : 'exclamation circle'}
        content={savedSuccess ? 'Save successful' : 'Did not save!'}
      />
      <Form onSubmit={handleSubmit} noValidate error>
        <Button
          color="green"
          type="submit"
          className="button"
          disabled={!detailsReady}
          content="Submit Building"
        />
        {
          !detailsReady &&
          <Label basic color='red'>Details Not Ready</Label>
        }
      
      <Form.Group widths='equal'>
        <Form.Field className="field is-grouped">
          <label>Building Name</label>
            <Form.Input type="text" name="name" onChange={handleChange} value={values.name || ''} required />
            {errors.name &&
              <Message error content={errors.name} />
            }
        </Form.Field>
        <Form.Field>
          <label>UID</label>
            <Form.Input disabled={editing} type="text" name="uid" onChange={handleChange} value={values.uid || ''} required />
            {errors.uid &&
              <Message error content={errors.uid} />
            }
        </Form.Field>
      </Form.Group>
      </Form>
      <hr />
      <div> 
        <ModelDetails
          form="building"
          isReady={isDetailsReady}
          onSubmit={onDetailsSubmit}
          initialDetails={values.details}
        />
      </div>
      </React.Fragment>
    }
    
  </div>
  );
};

export default withFirebase(BuildingForm);

