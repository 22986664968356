import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import ListItemMonster from './ListItemMonster';

import './monsterlist.css'

// this component should just accept a prop with the ID
// probably needs a wrapper component do pass an ID

class MonsterList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      monsters: [],
  };
}

  componentDidMount() {
    // const { factionID } = this.props.match.params;
    const { agendaID } = this.props;

    if (agendaID){
      this.setState({ loading: true });

      this.unsubscribe = this.props.firebase.monsters()
      .where('agendaID', '==', agendaID)
      .onSnapshot(snapshot => {
        let monsters = [];
    
        snapshot.forEach(doc =>
          monsters.push({ ...doc.data(), uid: doc.id }),
        );  
        this.setState({
          monsters,
          loading: false,
        });
      });
    }  
  };

  componentWillUnmount() {
    this.unsubscribe();
  }
      
  render() {
    let { monsters, loading } = this.state;
    let MonsterBoxes = [];
    const { selectedFactionID } = this.props;

    // should list be filtered
    if (selectedFactionID && !loading){
      monsters = monsters.filter(monster => monster.factionID === selectedFactionID);
    }

    if(monsters.length != 0){
      MonsterBoxes = monsters.map((monster, i) => ( 
        <ListItemMonster
          key={i}
          id={monster.uid} 
          name={monster.name}
          monster={monster}
          agendaID={monster.agendaID}
          displayAdd
        />
      ))
    }
    
    return (
      <div className="model-list container">
        {
          loading ?
          <p>Loading...</p>
          :
          <React.Fragment>
            { MonsterBoxes }
          </React.Fragment>          
        }
      </div>
    );
  }
}

MonsterList.propTypes = {
  agendaID: PropTypes.string,
  selectedFactionID: PropTypes.string
}

MonsterList.defaultProps = {
  agendaID: null,
  selectedFactionID: null
}

export default withFirebase(MonsterList);
