import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// env variables not working for some reason
// const config = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
// };

// this shouldn't be here, just trying to troubleshoot
const config = {
  apiKey: "AIzaSyAK_xaXkkZ8lfpfqKRLtr0onmKak4ooorc",
  authDomain: "monster-room.firebaseapp.com",
  databaseURL: "https://monster-room.firebaseio.com",
  projectId: "monster-room",
  storageBucket: "monster-room.appspot.com",
  messagingSenderId: "1017375553311"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    // app.firestore().enablePersistence().then(() => {
    //   this.db = app.firestore();
    // });

    this.db = app.firestore();
    this.db.enablePersistence();
    this.auth = app.auth();
  }

  signInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
  signOut = () => this.auth.signOut();

  // Agenda API
  agendas = () => this.db.collection('agendas');
  agenda = id => this.db.doc(`agendas/${id}`);

  // Faction API
  factions = () => this.db.collection('factions');
  faction = id => this.db.doc(`factions/${id}`);


  // Monster API
  monsters = () => this.db.collection('monsters');
  monster = id => this.db.doc(`monsters/${id}`);

  // Units API
  units = () => this.db.collection('units');
  unit = id => this.db.doc(`units/${id}`);

  // Buildings API
  buildings = () => this.db.collection('buildings');
  building = id => this.db.doc(`buildings/${id}`);

  // Assets API
  assets = () => this.db.collection('assets');
  asset = id => this.db.doc(`assets/${id}`);

  // Rules API
  rules = () => this.db.collection('rules');
  rule = id => this.db.doc(`rules/${id}`);

  // News Feed
  announcements = () => this.db.collection('newsfeed');
}

export default Firebase;