import React from 'react';
import { NavLink } from "react-router-dom";
import { withFirebase } from '../Firebase';
import { Menu } from 'semantic-ui-react'
import * as ROUTES from '../../constants/routes';

const activeClass = 'active-admin-menu-item';

// todo all the menu
function AdminMenu(props) {
  return (
    <Menu vertical className="admin-menu">
      <NavLink exact activeClassName={activeClass} to={`${ROUTES.ADMIN}`}>
        <Menu.Item name='rule'>
          Admin Home
        </Menu.Item>
      </NavLink>
      <NavLink activeClassName={activeClass} to={`${props.url}${ROUTES.ADD_RULE}`}>
        <Menu.Item name='rule'>
          Rule
        </Menu.Item>
      </NavLink>
      <NavLink activeClassName={activeClass} to={`${props.url}${ROUTES.ADD_FACTION}`}>
        <Menu.Item name='faction'>
          Faction
        </Menu.Item>
      </NavLink>
      <NavLink activeClassName={activeClass} to={`${props.url}${ROUTES.ADD_MONSTER}`}>
        <Menu.Item name='monster'>
          Monster
        </Menu.Item>
      </NavLink>
      <NavLink activeClassName={activeClass} to={`${props.url}${ROUTES.ADD_UNIT}`}>
        <Menu.Item name='unit'>
          Unit
        </Menu.Item>
      </NavLink>
      <NavLink activeClassName={activeClass} to={`${props.url}${ROUTES.ADD_BUILDING}`}>
        <Menu.Item name='building'>
          Building
        </Menu.Item>
      </NavLink>
      <NavLink activeClassName={activeClass} to={`${props.url}${ROUTES.ADD_ASSET}`}>
        <Menu.Item name='asset'>
          Asset
        </Menu.Item>
      </NavLink>
      </Menu>
  )
};

export default withFirebase(AdminMenu);