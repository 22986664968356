import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import ListItemAsset from './ListItemAsset';


import './assetslist.css'

// this component should just accept a prop with the ID
// probably needs a wrapper component do pass an ID

class AssetList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      assets: [],
  };
}

componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase.assets()
    .onSnapshot(snapshot => {
      let assets = [];
      snapshot.forEach(doc =>
        assets.push({ ...doc.data(), uid: doc.id }),
      );  
      
      this.setState({
        assets,
        loading: false,
      });
    });
};

componentWillUnmount() {
  this.unsubscribe();
}
      
  render() {
    let { assets, loading } = this.state;
    let AssetBoxes = [];
    const { selectedFactionID } = this.props;

    if(assets.length !== 0){
      AssetBoxes = assets.map((asset, i) => (
        <ListItemAsset
          key={i}
          id={asset.uid}
          name={asset.name}
          asset={asset}
          displayAdd
        />
      ))
    }
    
    return (
      <div className="assets-container model-list">
        {
          loading ?
          <p>Loading...</p>
          :
          <React.Fragment>
            { AssetBoxes }
          </React.Fragment>
        }
      </div>
    );
  }
}

AssetList.propTypes = {
  selectedFactionID: PropTypes.string
};

AssetList.defaultProps = {
  selectedFactionID: null
};

export default withFirebase(AssetList);
