import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';

import StatBlock from './StatBlock';
import AttackSection from './AttackSection';
import RulesSection from './RulesSection';

import './statcard.css'

class AssetCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      factionDisplay: '',
      loading: false
  };
}

componentDidMount() {
    // Assets dont have factions, no lookup needed
}

  render() {
    let {asset} = this.props;

    return (
      <div className={`stat-card-container asset-card`}>
        <div className="stat-card-top-row">
          <div className="stat-name-row">
            <div className="model-name">{asset.name}</div>
            <div className="model-subtitle">Asset</div>
          </div>
          
          <StatBlock
            def={asset.def}
          />

        </div>
        
        <RulesSection rulesById={asset.rulesById} />
        <AttackSection attacks={asset.attacks} />
        
      </div>
    );
  }
}

AssetCard.propTypes = {
  asset: PropTypes.object
};

AssetCard.defaultProps = {
  asset: {}
};

export default withFirebase(AssetCard);
