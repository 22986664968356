import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import ListItemBuilding from './ListItemBuilding';


import './buildinglist.css'

// this component should just accept a prop with the ID
// probably needs a wrapper component do pass an ID

class BuildingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      buildings: [],
  };
}

componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase.buildings()
    .onSnapshot(snapshot => {
      let buildings = [];
      snapshot.forEach(doc =>
        buildings.push({ ...doc.data(), uid: doc.id }),
      );  
      
      this.setState({
        buildings,
        loading: false,
      });
    });
};

componentWillUnmount() {
  this.unsubscribe();
}
      
  render() {
    let { buildings, loading } = this.state;
    let BuildingBoxes = [];
    const { selectedFactionID } = this.props;

    if(buildings.length !== 0){
      BuildingBoxes = buildings.map((building, i) => ( 
        <ListItemBuilding
          key={i}
          id={building.uid} 
          name={building.name}
          building={building}
          displayAdd
        />
      ))
    }
    
    return (
      <div className="buildings-container model-list">
        {
          loading ?
          <p>Loading...</p>
          :
          <React.Fragment>
            { BuildingBoxes }
          </React.Fragment>
        }
      </div>
    );
  }
}

BuildingList.propTypes = {
  selectedFactionID: PropTypes.string
}

BuildingList.defaultProps = {
  selectedFactionID: null
}

export default withFirebase(BuildingList);
