// helper function to count number of items in array
// expects array of objects (Units Buildings or Monsters)
const getCount = (arr) => {
  let returnCount = 0;
  arr.forEach(item => {
    if(item.count){
      returnCount += item.count;  
    }
  })
  return returnCount;
};

// exists in list helper function

export { getCount };