import React, { useState, useEffect } from 'react';
import { Message } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import _ from 'lodash';

import * as MSGS from '../../constants/dice-quotes';


function ResultsMessage(props) {
  const [header, setHeader] = useState('No Results');
  const [msg, setMsg] = useState('Enter numbers below');
  const [color, setColor] = useState('grey')

  useEffect(() => {
    // based on props, load message
    let result = _.get(props, 'result'); 
    setHeader(`${result}%`);

    if(result === null){
      setHeader('No Results');
      setMsg('Enter numbers below');
      setColor('grey');
    }
    else if(result >= 90){
      setMsg(MSGS.DEFINITE_CHANCE[_.random(0, MSGS.DEFINITE_CHANCE.length-1)]);
      setColor('blue');
    } 
    else if(result < 90 && result >= 70){
      setMsg(MSGS.GOOD_CHANCE[_.random(0, MSGS.GOOD_CHANCE.length-1)]);
      setColor('green');
    }
    else if(result < 70 && result >= 45){
      setMsg(MSGS.AVG_CHANCE[_.random(0, MSGS.AVG_CHANCE.length-1)]);
      setColor('yellow');
    }
    else if(result < 45 && result >= 20){
      setMsg(MSGS.BAD_CHANCE[_.random(0, MSGS.BAD_CHANCE.length-1)]);
      setColor('orange');
    }
    else{
      setMsg(MSGS.NO_CHANCE[_.random(0, MSGS.NO_CHANCE.length-1)]);
      setColor('red');
    }
    
  });

  return (
    <Message className="results" color={color}>
      <Message.Header>{header}</Message.Header>
      <p>{msg}</p>
    </Message>
  )
};

export default withFirebase(ResultsMessage);