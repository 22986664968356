import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Label, Dimmer, Loader, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { withFirebase } from '../../Firebase';
import useForm from '../useForm';
import validate from './MonsterFormValidationRules';

import ModelDetails from '../ModelDetails';


import './monsterForm.css';

// TODO form styles, success message and clear form after success
// TODO Let user edit existing docs
// TODO Alpha and Hyper Forms

function FactionForm(props) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submitMonster, validate);
  // submitMonster is the callback after validate is successful

  const [factions, setFactions] = useState([]);
  const [alphaReady, setAlphaReady] = useState(false);
  const [hyperReady, setHyperReady] = useState(false);
  //if editing prevent uid from changing
  const [editing, setEditing] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [savedSuccess, setSavedSucess] = useState(null);

  function submitMonster() {

    const fIndex = factions.findIndex(faction => faction.uid === values.factionID); 
    const agendaID = factions[fIndex].agendaID;
    
    props.firebase.monsters().doc(values.uid).set({
      name: values.name,
      factionID: values.factionID,
      agendaID: agendaID,
      hp: values.totalhp,
      triggerHyper: values.triggerHyper,
      alphaForm: values.alphaForm,
      hyperForm: values.hyperForm
    })
    .then(function() {
      setSavedSucess(true);
    })
    .catch(function(error){
      setSavedSucess(false);    })
  }

  useEffect(() => {
    // if editing 
    if ( props.match.params.id ){
      const editID = props.match.params.id;
      setLoading(true);
      setEditing(true);
      // set both forms to ready, they have data and don't nessecarily need to be saved
      setAlphaReady(true);
      setHyperReady(true);

      props.firebase.monster(editID)
      .onSnapshot(snapshot => {
        const entry = snapshot.data();         
        // set default values
        values.uid = editID;
        values.name = _.get(entry, 'name');
        values.factionID = _.get(entry, 'factionID');
        values.totalhp = _.get(entry, 'hp');
        values.triggerHyper = _.get(entry, 'triggerHyper');
        values.alphaForm = _.get(entry, 'alphaForm');
        values.hyperForm = _.get(entry, 'hyperForm');
        setLoading(false);
      });
    }

    //populate faction dropdown
    props.firebase.factions()
      .onSnapshot(snapshot => {
        let factions = [];

        snapshot.forEach(doc =>
            factions.push({ ...doc.data(), uid: doc.id }),
        );
        setFactions(factions);
      });

  }, []); // empty array as 2nd arg means this will only run on mount

  function onAlphaSubmit(vals){
    values.alphaForm = vals;
  }

  function onHyperSubmit(vals){
    values.hyperForm = vals;
  }

  function isAlphaReady(bool){
    setAlphaReady(bool);
  }
  function isHyperReady(bool){
    setHyperReady(bool);
  }

  return (
    <div className="form-container">
    {
      loading ?
      <Segment>
        <Dimmer active inverted>
          <Loader/>
        </Dimmer>
      </Segment>
      :
      <React.Fragment>
        <Message
          hidden={!savedSuccess}
          icon={savedSuccess ? 'check circle' : 'exclamation circle'}
          content={savedSuccess ? 'Save successful' : 'Did not save!'}
        />
      <Form className="general-details" onSubmit={handleSubmit} noValidate error>
        <Button
          color="green"
          type="submit"
          disabled={!alphaReady || !hyperReady}
        >
          Submit Monster
        </Button>
        {
          !alphaReady &&
          <Label basic color='red'>Alpha Not Ready</Label>
        }
        {
          !hyperReady &&
          <Label basic color='red'>Hyper Not Ready</Label>
        }

        <Form.Group>
          <Form.Field>
          <label>Monster Name</label>
            <input
              className={`input ${errors.name && 'is-danger'}`}
              type="text"
              name="name"
              onChange={handleChange}
              value={values.name || ''} 
              required 
            />
            {errors.name &&
              <Message error content={errors.name} />
            }
          </Form.Field>
           <Form.Field>   
          <label>UID</label>
            <input
              disabled={editing}
              className={`input ${errors.uid && 'is-danger'}`}
              type="text"
              name="uid"
              onChange={handleChange}
              value={values.uid || ''}
              required
            />
            {errors.uid &&
              <Message error content={errors.uid} />
            }
          </Form.Field>
          <Form.Field>
            <label>Faction</label>
              <select
                className="ui search dropdown"
                name="factionID"
                onChange={handleChange}
                value={values.factionID || ''}
              >
                <option key={0} >Choose Faction</option>
                {factions.map(faction =>
                    <option key={faction.uid} value={faction.uid}>{faction.name}</option>
                )};
              </select>
              {errors.factionID &&
                <Message error content={errors.factionID} />
              }
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field>
              <label>Total HP</label>
                <input
                  className={`input ${errors.totalhp && 'is-danger'}`}
                  type="text"
                  name="totalhp"
                  onChange={handleChange}
                  value={values.totalhp || ''}
                  required
                />
                {errors.totalhp &&
                  <Message error content={errors.totalhp} />
                }
            </Form.Field>
            <Form.Field>
          <label>Trigger Hyper HP</label>
            <input
              className={`input ${errors.triggerHyper && 'is-danger'}`}
              type="text"
              name="triggerHyper"
              onChange={handleChange}
              value={values.triggerHyper || ''}
              required
            />
            {errors.triggerHyper &&
                <Message error content={errors.triggerHyper} />
              }
            </Form.Field>
          </Form.Group>
      </Form>
      <hr />
      <div> 
        <h1>Alpha Form</h1>
        <ModelDetails
          form="alpha"
          isReady={isAlphaReady}
          onSubmit={onAlphaSubmit}
          initialDetails={values.alphaForm}
        />
      </div>
      <hr />
      <div> 
        <h1>Hyper Form</h1>
        <ModelDetails
          form="hyper"
          isReady={isHyperReady}
          onSubmit={onHyperSubmit}
          initialDetails={values.hyperForm}
        />
      </div>
      </React.Fragment>
    }
    
  </div>
      
  );
};

export default withFirebase(FactionForm);

