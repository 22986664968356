import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navigation from './components/Navigation/burger-menu';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import AgendaList from './components/AgendaList';
import AgendaContainer from './components/layout/AgendaContainer';
import Login from './components/Accounts/Login';
import AdminView from './components/Admin';
import RulesIndex from './components/RulesIndex';
import DiceOdds from './components/DiceOdds';
import { withFirebase } from './components/Firebase';
import * as ROUTES from './constants/routes';
import withGAnalytics from './components/withGAnalytics';
import { withAuthentication } from './components/Session';
import { ListContextProvider } from "./components/ListBuilder/ListContext";
import { Helmet } from "react-helmet";

import './App.css';

const App = () => (
  <ListContextProvider>
    <BrowserRouter>

    <Helmet>
      <title>Monster Room</title>
      <meta name="Monster Room" content="Model rules, list builder and dice calculator for Monsterpocalypse." />
    </Helmet>

    <div id="outer-container" className="app">

      <Navigation
        id="burger-menu"
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
      />
    <main id="page-wrap">
      <Header/>
      <div className="main-content animated fadeIn fast">
        <Switch>
        <Route exact path={ROUTES.LANDING} component={withGAnalytics(HomePage)} />
        <Route exact path={ROUTES.BROWSE} component={withGAnalytics(AgendaList)} />

        <Route exact path={ROUTES.BROWSE_AGENDA} component={withGAnalytics(AgendaContainer)} />
        <Route exact path={ROUTES.SIGN_IN} component={withGAnalytics(Login)} />

        <Route path={ROUTES.ADMIN} component={withGAnalytics(AdminView)} />

        <Route exact path={ROUTES.RULES_INDEX} component={withGAnalytics(RulesIndex)} />

        <Route exact path={ROUTES.DICE_ODDS} component={withGAnalytics(DiceOdds)} />

        </Switch>

      </div>
    </main>
    <div className="city-skyline"></div>
    </div>
    </BrowserRouter>
  </ ListContextProvider>

    );

export default withAuthentication(App);
