import React from 'react';
import { Icon } from 'semantic-ui-react'

import './statblock.css';

function UnitStats(props) {
  return (
    <div className="unit-block">
      <div className="unit-cost">
        <div className="cost-label">Cost: {props.cost}</div> 
      </div>
    {
      props.elite &&
        <div className="unit-elite">
          <Icon className="elite-star" name="star" />Elite
        </div>
    }
    </div>
  );
};

export default UnitStats;

