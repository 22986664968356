import React, { useContext, useEffect } from "react"
import { Message, Icon, Button, Divider } from 'semantic-ui-react';
import { ListContext } from "./ListContext";
import ListItemMonster from '../MonsterList/ListItemMonster';
import ListItemUnit from '../UnitList/ListItemUnit';
import ListItemBuilding from '../BuildingList/ListItemBuilding';
import ListNumbersDisplay from './ListNumbersDisplay';
import ListToolbar from './ListToolbar';
import { getCount } from './Util';

import './currentlist.css';

const CurrentList = () => {
  const {list, setList, errors, createNewList, stopListBuilding} = useContext(ListContext);

  let DisplayErrors;
  if(errors.length !== 0){
    DisplayErrors = errors.map((error, i) => ( 
      <Message
        key={i}
        className='list-message animated fadeIn fast'
        icon 
        info={error.level === 'info'}
        negative={error.level === 'danger'}
        warning={error.level === 'warning'}
        size='small'
        compact
      >
      <Icon className='list-message-icon' size='mini' name={error.level === 'info' ? 'info' : 'warning sign'} />
      <p>{error.msg}</p>
      </Message>
    ))
  } else {
    DisplayErrors = (
      <Message
        className='list-message animated fadeIn fast'
        icon
        success 
        size='small'
        compact
      >
      <Icon className='list-message-icon' size='mini' name="check circle" />
      <p>Valid {getCount(list.monsters)} Monster List</p>
      </Message>
    )
  }

  let MonsterBoxes;
  if(list.monsters.length !== 0){
    MonsterBoxes = list.monsters.map((monster, i) => ( 
      <ListItemMonster
        key={i}
        id={monster.uid} 
        name={monster.name}
        monster={monster}
        agendaID={monster.agendaID}
        displayRemove
        isListView
      />
    ))
  }

  let UnitBoxes;
  if(list.units.length !== 0){
    UnitBoxes = list.units.map((unit, i) => ( 
      <ListItemUnit
        key={i}
        id={unit.uid} 
        name={unit.name}
        unit={unit}
        agendaID={unit.agendaID}
        displayAdd
        displayRemove
        listCount={unit.count}
      />
    ))
  }

  let BuildingBoxes;
  if(list.buildings.length !== 0){
    BuildingBoxes = list.buildings.map((building, i) => ( 
      <ListItemBuilding
        key={i}
        id={building.uid} 
        name={building.name}
        building={building}
        displayAdd
        displayRemove
        listCount={building.count}
      />
    ))
  }

  let displayAgenda = 'List';
  if(list.agendaID){
    displayAgenda = list.agendaID === 'protectors' ? 'Protectors List' : 'Destroyers List'
  }

  return (
    <div className="current-list-container animated fadeIn fast">
        <div className="list-header">
          <h2>{displayAgenda}</h2>
        </div>
        <ListNumbersDisplay
          numMonsters= {getCount(list.monsters)}
          numUnits= {getCount(list.units)}
          numBuildings= {getCount(list.buildings)}
        />
      {DisplayErrors}
      <div className="current-list-toolbar">
        <ListToolbar />
      </div>
      <Divider />
      <div className="current-list-actual">
        {MonsterBoxes}
        <br />
        {UnitBoxes}
        <br />
        {BuildingBoxes}
      </div>      
    </div>
  )
}

export default CurrentList