export default function validate(values) {
  let errors = {};
  if (!values.def) {
    errors.def = 'DEF required';
  }

  const need4Speed = (!(values.modelType === 'building' || values.modelType === 'asset'));

  if (!values.spd && need4Speed) {
    errors.spd = 'SPD required';
  }
  
  return errors;
};