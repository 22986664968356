import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { ListContext } from "../ListContext";
import { Dropdown, Icon } from 'semantic-ui-react';
import moment from 'moment'
import { getCount } from '../Util';

import './listtoolbar.css';

// textarea styled offscreen
const ListToolbar = () => {
  const {list, createNewList, stopListBuilding} = useContext(ListContext);

  return (
    <React.Fragment>
        <Dropdown className="list-actions-dropdown" button text='List Actions' color="teal">
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => copyToClipboard()} icon="copy" text="Copy to Clipboard"/>
            <Dropdown.Item onClick={createNewList} icon="trash alternate" text="Clear List"/>
            <Dropdown.Item onClick={stopListBuilding} icon="close" text="Cancel Listbuilding"/>
          </Dropdown.Menu>
        </Dropdown>

        <textarea id="list-text" value={formatList(list)}  readOnly/>
    </React.Fragment>
  )
}

// copies to clipboard
const copyToClipboard = () => {
  const copyText = document.getElementById("list-text");
  copyText.select();
  document.execCommand('copy');
};

const formatList = (list) => {
  const listCopy = {...list};

  let listText = '';
  let agenda ='Not Set';
  if(listCopy.agendaID){
    agenda = listCopy.agendaID === 'protectors' ? 'Protectors' : 'Destroyers';
  }
  listText += `Agenda: ${agenda} \n`;

  listText += `Monsters: ${getCount(listCopy.monsters)}\n`;
  listCopy.monsters.forEach(monster => {
    listText += `\t ${monster.name}\n`
  });

  listText += `Units: ${getCount(listCopy.units)}\n`;
  listCopy.units.forEach(unit => {
    listText += `\t ${unit.count}x ${unit.name}\n`
  });

  listText += `Buildings: ${getCount(listCopy.buildings)}\n`;
  listCopy.buildings.forEach(building => {
    listText += `\t ${building.count}x ${building.name}\n`
  });

  listText += `Generated by Monster Room `;
  const now = moment().format('MM/DD/YY-H:mm:ss');
  listText += `${now} \n`;

  return listText;
}

export default ListToolbar;