import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../../Firebase';

import './attackdice.css'

class AttackDice extends Component {
  constructor(props) {
    super(props);
}
      
  render() {
    const { aDice, bDice, pDice} = this.props;
    const bDiceDisplay = (parseInt(bDice, 10) > 0) ? bDice : '';

    // display special rules if they exist
    return (
      <div className="attack-dice-container">
        <div className="action-dice dice hexagon"> 
          {aDice}
        </div>
        <div className="boost-dice dice"> 
          {bDiceDisplay}
        </div>
        {
          // dont show 0 pDice
          parseInt(pDice, 10) > 0 ?
          <div className="power-dice dice"> 
            {pDice}
          </div>
          : null

        }
        
      </div>
    );
    
  }
}

AttackDice.propTypes = {
  aDice: PropTypes.string,
  bDice: PropTypes.string,
  pDice: PropTypes.string
}

AttackDice.defaultProps = {
  aDice: '0',
  bDice: '0',
  pDice: '0'
}

export default withFirebase(AttackDice);
