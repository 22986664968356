import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withFirebase} from "../../Firebase";
import {Container, Form, Button, Message } from 'semantic-ui-react';
import _ from 'lodash';

import useForm from '../../Forms/useForm';
import validate from './LoginValidationRules';


function Login(props) {
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(submitLogin, validate);

   const [location, setLocation] = useState('');
   const [referrer, setReferrer] = useState(null);
   const [loginError, setLoginError] = useState(false);

   function submitLogin() {
    const { username, password } = values;

    props.firebase
      .signInWithEmailAndPassword(username, password)
      .then(() => {
        props.history.push(referrer);
      })
      .catch(error => {
        setLoginError(true);
      });

  }

    useEffect(() => {
        setLocation(props.location);
        setReferrer(_.get(props, 'location.state.referrer'));

      }, []); // empty array as 2nd arg means this will only run on mount


    return (
        <Container text>
            Login page
            Going to {referrer ? referrer : 'home'} after login
            {
                loginError &&
                <Message error content="Incorrect Login" />
            }
            <Form onSubmit={handleSubmit} noValidate error>
                <Form.Group widths='equal'>
                <Form.Field>
                    <Form.Input
                        name="username"
                        type="text"
                        placeholder="Username/Email"
                        onChange={handleChange}
                        value={values.username || ''}
                    />
                    {errors.username &&
                        <Message error content={errors.username} />
                    }
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        name="password"
                        type="password"
                        placeholder="Password"
                        onChange={handleChange}
                        value={values.password || ''}
                    />
                     {errors.password &&
                        <Message error content={errors.password} />
                    }
                </Form.Field>
                </Form.Group>
                <Button color="blue" type="submit" className="button" >
                    Login
                </Button>
            </Form>
        </Container>
    );
}

export default withFirebase(Login);