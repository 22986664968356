import React from 'react';
import { Container } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';

import './footer.css';

function Footer(props) {

  return (
      <Container className="footer-container animated fadeIn">
        <div className="monpoc-disclaimer">
          <p>Monsterpocalypse is the property of Privateer Press. 
            Monster Room is not associated with Privateer Press. Please send bugs, 
            feedback and suggestions to <a className="bold-link" href = "mailto: admin@monsterroom.app">admin@monsterroom.app</a>.</p>
        </div>
      </Container>
  )
};

export default withFirebase(Footer);