import React from 'react';
import { Icon } from 'semantic-ui-react'

import './statblock.css';

function StatBlock(props) {
  return (
    <div className="stat-block">
    {
      props.spd &&
        <div className="stat-block-spd">
        <div className="stat-label">SPD</div>
        <div className="stat-value">{props.spd}</div>
        { props.highMobility &&
          <Icon className="stat-block-hm" circular inverted size="small" name="angle double up" />
        }
      </div>
    }
      
      <div className="stat-block-def">
        <div className="stat-label">DEF</div> 
        <div className="stat-value">{props.def}</div>
      </div>
    </div>
  );
};

export default StatBlock;

