import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withFirebase } from '../../Firebase';

import './healthtrack.css'

class HealthTrack extends Component {
  constructor(props) {
    super(props);

    this.state = {};
}

  render() {
    const {totalHealth, triggerHyper, toggleForm, currentForm} = this.props;
    const totalInt = parseInt(totalHealth, 10);
    const tiggerInt = parseInt(triggerHyper, 10);
    
    let healthBoxes = [];

    if(currentForm === 'alpha'){
      for( let x = totalInt; x > tiggerInt; x-- ){
        healthBoxes.push(<div key={x} className="health-box">{x}</div>);
      }
      healthBoxes.push(<button key={'hyper'} className="go-hyper bright-hover" onClick={toggleForm}>Go Hyper!</button>);

    } else if (currentForm === 'hyper') {

      healthBoxes.push(<button key={'alpha'} className="go-alpha bright-hover" onClick={toggleForm}>Go Alpha</button>);
      for( let x = tiggerInt; x > 0; x-- ){
        healthBoxes.push(<div key={x} className="health-box">{x}</div>);
      }
      healthBoxes.push(<Icon size="large" name="heartbeat" />);
    }

    
    return (
      <div className="health-track-container">
       {healthBoxes}
      </div>
    );
    
  }
}

HealthTrack.propTypes = {
  totalHealth: PropTypes.string.isRequired,
  triggerHyper: PropTypes.string,
  toggleForm: PropTypes.func,
  currentForm: PropTypes.string
}

HealthTrack.defaultProps = {
  triggerHyper: null,
  toggleForm: () => {},
  currentForm: 'alpha'
}

export default withFirebase(HealthTrack);
