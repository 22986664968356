import React from 'react';
import { Route } from "react-router-dom";
import { Container } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import {AuthUserContext} from '../Session';
import {withAuth} from "../Accounts/withAuth";
import * as ROUTES from '../../constants/routes';

import AdminMenu from './AdminSidebar';
import AdminPageContent from './AdminPageContent';
import FactionForm from '../Forms/FactionForm';
import MonsterForm from '../Forms/MonsterForm';
import NewRuleForm from '../Forms/NewRuleForm';
import UnitForm from '../Forms/UnitForm';
import BuildingForm from '../Forms/BuildingForm';
import AssetForm from '../Forms/AssetForm';

import './adminpages.css';

function AdminView({ match }) {
  return (
    <div className="admin-page-container">
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? 
          <React.Fragment>
            <div className="admin-sidebar">
              <AdminMenu url={match.url}/>
            </div>

            <Container text className="admin-content">
              <Route exact path={match.url} component={AdminPageContent}/>

              <Route exact path={`${match.url}${ROUTES.ADD_FACTION}`} component={FactionForm}/>
              <Route path={`${match.url}${ROUTES.ADD_FACTION}/:id`} render={(props) => <FactionForm {...props}/>}/>
              
              <Route exact path={`${match.url}${ROUTES.ADD_BUILDING}`} component={BuildingForm}/>
              <Route path={`${match.url}${ROUTES.ADD_BUILDING}/:id`} render={(props) => <BuildingForm {...props}/>}/>
              
              <Route exact path={`${match.url}${ROUTES.ADD_MONSTER}`} component={MonsterForm}/>
              <Route path={`${match.url}${ROUTES.ADD_MONSTER}/:id`} render={(props) => <MonsterForm {...props}/>}/>
              
              <Route exact path={`${match.url}${ROUTES.ADD_UNIT}`} component={UnitForm}/>
              <Route path={`${match.url}${ROUTES.ADD_UNIT}/:id`} render={(props) => <UnitForm {...props}/>}/>

              <Route exact path={`${match.url}${ROUTES.ADD_ASSET}`} component={AssetForm}/>
              <Route path={`${match.url}${ROUTES.ADD_ASSET}/:id`} render={(props) => <AssetForm {...props}/>}/>
              
              <Route exact path={`${match.url}${ROUTES.ADD_RULE}`} component={NewRuleForm}/>
              <Route path={`${match.url}${ROUTES.ADD_RULE}/:id`} render={(props) => <NewRuleForm {...props}/>}/>

            </Container>
          </React.Fragment>
          :
          <p>Not Authorized</p>
        }
      </AuthUserContext.Consumer>
      
    </div>
  )
};

export default withFirebase(AdminView);