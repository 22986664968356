import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';

import HealthTrack from './HealthTrack/HealthTrack';
import StatBlock from './StatBlock';
import AttackSection from './AttackSection';
import RulesSection from './RulesSection';

import './statcard.css'


class MonsterCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: 'alpha',
      factionDisplay: '',
      loading: false
  };
}

componentDidMount() {
  const factionID = this.props.monster.factionID;

  this.setState({ loading: true });

  this.unsubscribe = this.props.firebase.faction(factionID)
  .onSnapshot(snapshot => {
    //just grabbing name
    const faction = snapshot.data(); 

    this.setState({
      factionDisplay: faction.name,
      loading: false,
    });
  });
}

componentWillUnmount() {
  this.unsubscribe();
}

toggleForm = () => {
  this.setState({
    form: this.state.form === 'alpha' ? 'hyper' : 'alpha'
  });
}

// Monster doc structure in docs/monsterDataStructure.txt      
  render() {
    // todo
    // for units add elite symbol?
    // can reuse this card for all models?
    // figure out what gets passed to this component - generic model object perhaps?

    let {monster} = this.props;
    let {form, factionDisplay} = this.state;
    let abilites = (form === 'alpha') ? monster.alphaForm : monster.hyperForm;
    const triggerHyper = monster.triggerHyper;

    const formTagline = (form === 'alpha') ? 'ALPHA FORM' : 'HYPER FORM';

    return (
      <div className={`stat-card-container ${form}-card`}>
        <div className="monster-form">{formTagline}</div>
        <div className="stat-card-top-row">
          <div className="stat-name-row">
            <div className="model-name">{monster.name}</div>
            <div className="model-subtitle">{factionDisplay} Monster</div>
          </div>
          
          <StatBlock
            spd={abilites.spd}
            def={abilites.def}
            highMobility={abilites.highMobility}
          />

        </div>
        
        <HealthTrack
          totalHealth={monster.hp}
          triggerHyper={triggerHyper}
          toggleForm={this.toggleForm}
          currentForm={form}
        />
        
        <RulesSection rulesById={abilites.rulesById} />
        <AttackSection attacks={abilites.attacks} />
        
      </div>
    );
  }
}

MonsterCard.propTypes = {
  monster: PropTypes.object
}

MonsterCard.defaultProps = {
  monster: {}
}

export default withFirebase(MonsterCard);
