export const LANDING = '/';

export const BROWSE = '/browse';
export const BROWSE_AGENDA = '/browse/:agendaID';

export const SIGN_IN = '/signin';
export const ADMIN = '/admin';

export const ADD_FACTION = '/add-faction';
export const ADD_MONSTER = '/add-monster';
export const ADD_RULE = '/add-rule';
export const ADD_UNIT = '/add-unit';
export const ADD_BUILDING = '/add-building';
export const ADD_ASSET = '/add-asset';


export const RULES_INDEX ='/rules';

export const DICE_ODDS ='/dice';


