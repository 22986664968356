import React, { useState, useEffect } from 'react';
import { Message, Icon } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';

import './newsfeed.css';

function NewsFeed(props) {

  return (
    <div>
      <h3>News Feed</h3>
      <Message
          color='teal'
      >
          <Message.Header>2024 Yearly Update!</Message.Header>
          <div>
              <Icon name="star outline" />March 31st, 2024
          </div>
          <p>2024 yearly update is now live!
          </p>
      </Message>

      <Message
          color='teal'
      >
          <Message.Header>2023 Yearly Update!</Message.Header>
          <div>
              <Icon name="star outline" />January 15th, 2023
          </div>
          <p>All changes from the 2023 yearly update are now live. Check them out!
          </p>
      </Message>
    </div>
  )
};

export default withFirebase(NewsFeed);