import React, { Component } from 'react';
import {Container} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'; 
import { withFirebase } from '../Firebase';

import './agendalist.css'


class AgendaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      agendas: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase.agendas()
    .onSnapshot(snapshot => {
      let agendas = [];

      snapshot.forEach(doc =>
        agendas.push({ ...doc.data(), uid: doc.id }),
      );
      
      this.setState({
        agendas,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { agendas, loading } = this.state;

    let AgendaBoxes = null;
    if( agendas ){
      AgendaBoxes = agendas.map((agenda, i) => (
          <Link to={{ pathname: `browse/${agenda.uid}`}} key = {i}> 
            <div 
              className = {`agenda-box bright-hover browse-${agenda.uid}`}
            >
              {agenda.name}
            </div>
          </Link>
      ))
    }
    
    return (
      <Container className="select-agenda">
        {loading && <div>Loading ...</div>}
        <h1>The Battle For Earth Has Begun</h1>
        <div className="hero-tagline">Choose Your Side</div>
        <div className="all-agendas-box">
          { AgendaBoxes }
        </div>
        
      </Container>
    );
  }

}

AgendaList.propTypes = {
  // onAgendaSelect: PropTypes.func
};

AgendaList.defaultProps = {
  // onAgendaSelect: undefined
};

export default withFirebase(AgendaList);
