import React, { useState, useEffect } from 'react';
import { Form, Message, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { withFirebase } from '../../Firebase';
import _ from 'lodash';
import useForm from '../useForm';
import validate from './NewRuleValidationRules';


function NewRuleForm(props) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submitRule, validate);
  // submitFaction is the callback after validate is successful

  function submitRule() {
    props.firebase.rules().doc(values.uid).set({
      name: values.name,
      text: values.text
    })
    .then(function() {
      setSavedSucess(true);
    })
    .catch(function(error){
      console.log("Error saving ", error)
      setSavedSucess(false);
    })
  }

  //if editing prevent uid from changing
  const [editing, setEditing] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [savedSuccess, setSavedSucess] = useState(null);

  useEffect(() => {
    // if editing
    if ( props.match.params.id ){
      const editID = props.match.params.id;
      setLoading(true);
      setEditing(true);

      props.firebase.rule(editID)
      .onSnapshot(snapshot => {
        const entry = snapshot.data();         
        // set default values
        values.uid = editID;
        values.name = _.get(entry, 'name');
        values.text = _.get(entry, 'text');
        setLoading(false);
      });
    }
  }, []); // empty array as 2nd arg means this will only run on mount

  return (
    <div className="form-container">
    {
      loading ? 
      <Segment>
        <Dimmer active inverted>
          <Loader/>
        </Dimmer>
      </Segment>
      :
      <React.Fragment>
      <Message
          hidden={!savedSuccess}
          icon={savedSuccess ? 'check circle' : 'exclamation circle'}
          content={savedSuccess ? 'Save successful' : 'Did not save!'}
        />
      <Form onSubmit={handleSubmit} noValidate error>
        <Form.Button color="green" type="submit" className="button">Submit Rule</Form.Button>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>Rule Name</label>
              <Form.Input type="text" name="name" onChange={handleChange} value={values.name || ''} required />
              {errors.name &&
                <Message error content={errors.name} />
              }
          </Form.Field>
          <Form.Field>
            <label>UID</label>
            <div className="control">
              <Form.Input disabled={editing} type="text" name="uid" onChange={handleChange} value={values.uid || ''} required />
              {errors.uid &&
                <Message error content={errors.uid} />
              }
            </div>
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>Rule Text</label>
          <div className="control">
            <Form.TextArea
              type="textarea" name="text"
              onChange={handleChange}
              value={values.text || ''} required
              placeholder="Enter rule text"
            />
            {errors.text &&
                <Message error content={errors.text} />
              }
          </div>
        </Form.Field>      
      </Form>
      </React.Fragment>
    }
    </div>
  );
};

export default withFirebase(NewRuleForm);

