import React, { useState, useEffect } from 'react';
import { Container, Dropdown, Button } from 'semantic-ui-react'
import { withFirebase } from '../../Firebase';
import { Link } from "react-router-dom";
import * as ROUTES from '../../../constants/routes';

// props: type(rules, factions, units, buildings, monsters)

function EditExistingDropdown(props) {
  const [entries, setEntries] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemRoute, setItemRoute] = useState(null);
  const [labelText, setLabelText] = useState('Item')

  useEffect(() => {
    let fireFunc;
    switch(props.type){
      case 'rules':
        fireFunc = props.firebase.rules();
        setItemRoute(ROUTES.ADD_RULE);
        setLabelText('Rule');
        break;
      case 'factions':
        fireFunc = props.firebase.factions();
        setItemRoute(ROUTES.ADD_FACTION);
        setLabelText('Faction');
        break;
      case 'monsters':
        fireFunc = props.firebase.monsters();
        setItemRoute(ROUTES.ADD_MONSTER);
        setLabelText('Monster');
        break;
      case 'units':
        fireFunc = props.firebase.units();
        setItemRoute(ROUTES.ADD_UNIT);
        setLabelText('Unit');
        break;
      case 'buildings':
        fireFunc = props.firebase.buildings();
        setItemRoute(ROUTES.ADD_BUILDING);
        setLabelText('Building');
        break;
      case 'asset':
        fireFunc = props.firebase.assets();
        setItemRoute(ROUTES.ADD_ASSET);
        setLabelText('Asset');
        break;
      default:
        //todo handle bad type
        fireFunc = props.firebase.units();
        setItemRoute(ROUTES.ADD_UNIT);
    }

    fireFunc
      .onSnapshot(snapshot => {
        let items = [];

        snapshot.forEach(doc =>
          items.push(
            {key: doc.id, value: doc.id, text: doc.data().name}
          ),
        );

        setEntries(items);
      });
  }, []); // empty array as 2nd arg means this will only run on mount

  function handleSelection(event, {value}){
    setSelectedItem(value);
    
  };

  return (
    <Container text className="admin-home-content">
      <Dropdown
        placeholder={`Select ${labelText} to Edit`}
        search
        selection
        options={entries}
        onChange={handleSelection}
      />
      {
        selectedItem &&
        <Link
          to={`${ROUTES.ADMIN}${itemRoute}/${selectedItem}`}
        >
          <Button color="green" content={`Edit Selected ${labelText}`}/>
        </Link>

      }
      
      
    </Container>
  )
};

export default withFirebase(EditExistingDropdown);