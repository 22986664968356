import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as ROUTES from '../../constants/routes';

// only used for desktop menu currently
class MenuItems extends Component {
  render() {
    const actClass = "active-item";
    return (
      <React.Fragment>
        {/* <NavLink exact activeClassName={actClass} className={this.props.class} to={ROUTES.LANDING}>
          Home
        </NavLink> */}
        <NavLink exact activeClassName={actClass} className={this.props.class} to={`${ROUTES.BROWSE}/destroyers`}>
          Destroyers
        </NavLink>
        <NavLink exact activeClassName={actClass} className={this.props.class} to={`${ROUTES.BROWSE}/protectors`}>
          Protectors
        </NavLink>
        <NavLink exact activeClassName={actClass} className={this.props.class} to={`${ROUTES.RULES_INDEX}`}>
          Rules
        </NavLink>
        <NavLink exact activeClassName={actClass} className={this.props.class} to={`${ROUTES.DICE_ODDS}`}>
          Dice
        </NavLink>
      </React.Fragment>
    );
  }
};

MenuItems.propTypes = {
  class: PropTypes.string
}

MenuItems.defaultProps = {
  class: ''  
}

export default MenuItems; 