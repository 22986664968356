import React, { useState, useEffect } from 'react';
import { Container, Checkbox } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import NumberInput from './NumberInput';
import {CalcDefHit} from './CalcDefHit';
import ResultsMessage from './ResultMessage';
import {Helmet} from 'react-helmet';

import './diceodds.css';

function DiceOdds(props) {
  const [def, setDef] = useState(0);
  const [aDice, setADice] = useState(0);
  const [bDice, setBDice] = useState(0);
  const [pDice, setPDice] = useState(0);
  const [reroll, setReroll] = useState(false);
  const [result, setResult] = useState(null);
  // placeholder if we decide to toggle calc
  const [willCalc, setWillCalc] = useState(true);

  // currently doesn't need to be broken out like this
  function defChanged(v){
    setDef(v);
  }
  function aDiceChanged(v){
    setADice(v);
  }
  function bDiceChanged(v){
    setBDice(v);
  }
  function pDiceChanged(v){
    setPDice(v);
  }

  function calculateOdds(){
    const r = CalcDefHit(def, aDice, bDice, pDice, reroll);
    // 2d array, index corresponds to def. 2nd value is percentage
    // if def is greater than array length, value isn't possible
    if(r[def]){
      setResult(r[def][1]);
    }
    else{
      setResult(0);
    }
  }

  useEffect(() => {
    if( (aDice + bDice + pDice) > 0 ){
      if(willCalc){
        calculateOdds();
      }
    }
    if( (aDice + bDice + pDice) === 0 ){
      setResult(null);
    }
    if( def === 0 ){
      setResult(null);
    }
  });

  return (
    <Container text className="dice-odds-container animated fadeIn fast">
      <Helmet>
        <title>Dice Calculator</title>
        <meta name="Monster Room" content="Model rules, list builder and dice calculator for Monsterpocalypse." />
      </Helmet>

      <h2>Dice Calculator</h2>
      <ResultsMessage result={result} />
      <div className="all-inputs">
        <div className="odds-input-row">
          Defense<NumberInput onValueChanged={defChanged}/>
        </div>
        <hr />
        <div className="odds-input-row">
          White Dice<NumberInput className="white-dice" onValueChanged={aDiceChanged}/>
        </div>
        <div className="odds-input-row"> 
          Blue Dice<NumberInput className="blue-dice" onValueChanged={bDiceChanged}/>
        </div>
        <div className="odds-input-row">
          Red Dice<NumberInput className="red-dice" onValueChanged={pDiceChanged}/>
        </div>
        <div className="odds-checkbox-row">
          <Checkbox toggle name='reroll' checked={reroll} onChange={() => setReroll(!reroll) } label='With Reroll' />
        </div>
      </div>
      {/* </Container> */}
    </Container>
  )
};

export default withFirebase(DiceOdds);