import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Label, Modal, Checkbox } from 'semantic-ui-react';
import _ from 'lodash';

import { withFirebase } from '../../Firebase';
import useForm from '../useForm';
import validate from './ModelDetailsValidationRules';

import EnterAttackForm from '../EnterAttackForm';
import CardAttack from '../../StatCard/CardAttack/CardAttack';

import SelectRuleForm from '../SelectRuleForm';
import CardRule from '../../StatCard/CardRule/CardRule';


// TODO form styles, success message and clear form after success
// TODO Let user edit existing docs

function ModelDetails(props) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    hasEdits
  } = useForm(submitForm, validate);

  // does form have unsaved changes
  const [savedState, setSavedState] = useState(true);
  // checkbox state
  const [highMobility, setHighMobility] = useState(false);

  const [attackModalOpen, setAttackModal] = useState(false);
  const [displayAttacks, setDisplayAttacks] = useState([]);
  
  const [ruleModalOpen, setRulesModal] = useState(false);
  const [displayRules, setDisplayRules] = useState([]);

  //if editing prevent uid from changing
  const [editing, setEditing] = useState(false);
  const [loading, setLoading ] = useState(false);


  function submitForm() {
    values.highMobility = highMobility;
    setSavedState(true);
    props.isReady(true); // lets parent know if ready
    props.onSubmit(values); //callback
  }

  useEffect(() => {
    if(props.initialDetails){
      setLoading(true);
    }
    values.modelType = props.form;
    values.spd = _.get(props, 'initialDetails.spd');
    values.def = _.get(props, 'initialDetails.def');
    values.attacks = _.get(props, 'initialDetails.attacks', []);
    values.rulesById = _.get(props, 'initialDetails.rulesById', []);
    if( _.get(props, 'initialDetails.highMobility')){
      setHighMobility(true);
    };
    setLoading(false);
    
    //generate initial displays
    generateDisplayAttacks(values.attacks);
    generateDisplayRules(values.rulesById);
    
  }, []); // empty array as 2nd arg means this will only run on mount

  function removeAttack(index) {
    values.attacks.splice(index, 1);
    generateDisplayAttacks(values.attacks);
    setSavedState(false);
  }

  function onAttackSaved(attack){
    // add attack to values
    values.attacks.push(attack);
    setAttackModal(!attackModalOpen);
    generateDisplayAttacks(values.attacks);
    setSavedState(false);
  }

  function generateDisplayAttacks(){
    // display saved attacks with remove button
    const attacksArr = values.attacks.map((attack,i) =>
      <div className="display-with-remove-button fill-remaining-space" key={i}>
        <CardAttack
          type={attack.type}
          range={attack.type === 'blast' ? attack.range : null}
          aDice={attack.aDice}
          bDice={attack.bDice}
          pDice={attack.pDice}
          rulesById={attack.rulesById}
        />
        <Button inverted color="red" type="button" className="remove-button" onClick={() => removeAttack(i)}>Remove</Button>
      </div>
    );
    setDisplayAttacks(attacksArr);
  }
  /* End Attack Functions */
 
  /* RULES FUNCTIONS */
  function addRule(rule){
    setRulesModal(!ruleModalOpen);
    values.rulesById.push(rule.uid);
    generateDisplayRules(values.rulesById);
    setSavedState(false);
  }

  function removeRule(ruleID){
    values.rulesById = _.pull(values.rulesById, ruleID);
    generateDisplayRules(values.rulesById);
    setSavedState(false);
  }

  // generate rules html for display
  function generateDisplayRules(arr){
    const rulesArr = values.rulesById.map((rule,i) =>
      <div className="display-with-remove-button" key={i}>
        <CardRule key={rule} className="fill-remaining-space" ruleID={rule}/>
        <Button inverted color="red" type="button" className="remove-button" onClick={() => removeRule(rule)}>Remove</Button>
      </div>
    );
    setDisplayRules(rulesArr);
  }
  /* End rules functions */

  function toggleHM(){
    setSavedState(false);
    setHighMobility(!highMobility);
  }

  const showSpeed = (!(values.modelType === 'building' || values.modelType === 'asset'));

  return (
    <div>
      <Form className="general-details" onSubmit={handleSubmit} noValidate error>
        <Form.Group>
          <Form.Field>
          <Button color="blue" type="submit" className="save-monster-form">{`Save ${props.form} Details`}</Button>
          {
            (hasEdits || !savedState ) &&
            <Label basic color='red'> Unsaved Changes</Label>
          }
          {
            (!hasEdits && savedState) &&
            <Label basic color='green'>No Changes</Label>
          }
          </Form.Field>
        </Form.Group>

        <Form.Group>
          {
            showSpeed &&
            <React.Fragment>
              <Form.Field>
              <label>SPD</label>
                <Form.Input type="text" name="spd" onChange={handleChange} value={values.spd || ''} required />
                {errors.spd &&
                  <Message error content={errors.spd} />
                }
              </Form.Field>
                <Form.Field>
                  <Form.Checkbox name="highMobility" checked={highMobility} onChange={toggleHM} label="High Mobility"/>
              </Form.Field>
            </React.Fragment>
          }
          <Form.Field>
          <label>DEF</label>
            <Form.Input type="text" name="def" onChange={handleChange} value={values.def || ''} required />
            {errors.def &&
              <Message error content={errors.def} />
            }
          </Form.Field>      
        </Form.Group>
      </Form>  
      <Form.Group>
        <Button type="button" color="blue" onClick={() => setRulesModal(!ruleModalOpen)}>Add Rule</Button>
        <Modal
            open={ruleModalOpen}
            onClose={() => setRulesModal(!ruleModalOpen)}
            center="true"
            style={{'maxWidth': '500px'}}
          >
            <SelectRuleForm onRuleSubmit={addRule}/>
        </Modal>
      
        <Button type="button" color="blue" onClick={() => setAttackModal(!attackModalOpen)}>Add Attack</Button>
        <Modal
            open={attackModalOpen}
            onClose={() => setAttackModal(!attackModalOpen)}
            closeIcon
          >
            <EnterAttackForm onAttackSaved={onAttackSaved}/>
        </Modal>
      </Form.Group>
      <div>
          {displayRules}
          {displayAttacks}
      </div>
    </div>
  );
};

export default withFirebase(ModelDetails);

