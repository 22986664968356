import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from '../../Firebase';
import CardRule from '../CardRule/CardRule';
import AttackDice from '../AttackDice/AttackDice';

import './cardattack.css'

class CardAttack extends Component {
  constructor(props) {
    super(props);
}
      
  render() {
    // todo display special rules if they exist
    // todo display RNG for range attack
    const{ type, aDice, bDice, pDice, rulesById, range} = this.props;
    
    let displayRules = '';
    if(rulesById.length >= 1){
      displayRules = rulesById.map((ruleID, i) =>
        <CardRule key ={i} ruleID={ ruleID }/>
      )
    }
    // capitalize first letter
    const typeDisplay = type.charAt(0).toUpperCase() + type.slice(1);

    // range is 0 by default, don't want to show range 0
    const rangeDisplay = (range && range !== 0) ? <span>RNG {range}</span> : ''

    return (
      <div className="attack-box">
        <div className="attack-info"> 
          <div className="attack-type">{typeDisplay}</div>
          {rangeDisplay}
          <div className="attack-dice">
          <AttackDice
            aDice = {aDice}
            bDice = {bDice}
            pDice = {pDice}
          />
          </div>
        </div>
        { 
          (rulesById.length >= 1) ?
          <div className="attack-rules">
            {displayRules}
          </div>  
          :
          ''
        }
      </div>
    );
  }
}

CardAttack.propTypes = {
  type: PropTypes.string.isRequired,
  rulesById: PropTypes.array,
  aDice: PropTypes.string,
  bDice: PropTypes.string,
  pDice: PropTypes.string,
  range: PropTypes.string
}

CardAttack.defaultProps = {
  rulesById: [],
  aDice: '0',
  bDice: '0',
  pDice: '0',
  range: null
}

export default withFirebase(CardAttack);
