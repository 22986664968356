export default function validate(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Name required';
  } 
  if (!values.uid) {
    errors.uid = 'UID required';
  }
  if (!values.text) {
    errors.text = 'Rule Text Required';
  }
  return errors;
};