import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from "uuid";

import CardRule from '../CardRule/CardRule';

import './rulesSection.css'


class RulesSection extends Component {
  constructor(props) {
    super(props);
}

  render() {
    const {rulesById} = this.props;
    let displayRules = null;

    if(rulesById.length > 0){
      displayRules = rulesById.map((ruleID, i) =>
        <CardRule key ={uuid.v4()} ruleID={ ruleID }/>
      )
    }

    return (
      <React.Fragment>
        {
          displayRules &&
          <div className="special-rules">
            <div className="special-rules-label">Special Rules</div>
            {displayRules}
          </div>
        }
        </React.Fragment>
    );
  }
}


RulesSection.propTypes = {
  rulesById: PropTypes.array
}

RulesSection.defaultProps = {
  rulesById: []
}

export default RulesSection;
