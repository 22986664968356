import React from 'react';
import { Container } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';

import EditExistingDropdown from './EditDropdown/EditExistingDropdown';

function AdminPageContent(props) {
  
  return (
    <Container text className="admin-home-content">
      <h2>Edit Content</h2>
      <EditExistingDropdown type="rules"/>
      <EditExistingDropdown type="factions"/>
      <EditExistingDropdown type="monsters"/>
      <EditExistingDropdown type="units"/>
      <EditExistingDropdown type="buildings"/>
      <EditExistingDropdown type="asset"/>
    </Container>
  )
};

export default withFirebase(AdminPageContent);