import React, { useState, useEffect } from 'react';
import { Container, Accordion, Input, Button  } from 'semantic-ui-react'
import { Helmet } from "react-helmet";
import { withFirebase } from '../Firebase';
import _ from 'lodash';
import * as LINKS from '../../constants/links';

import './rulesindex.css';

function RulesIndex(props) {
  const [allRules, setAllRules ] = useState([]);
  const [panels, setPanels ] = useState([]);

  useEffect(() => {
    props.firebase.rules()
      .onSnapshot(snapshot => {
        let r = [];
        snapshot.forEach(doc =>
          r.push({ ...doc.data(), uid: doc.id }),
        );
        
        setAllRules(r);
        generatePanels(r);
      });
  }, []); // empty array as 2nd arg means this will only run on mount

  function generatePanels(r){
    const p = r.map((rule, i) => ({
      key: `panel-${i}`,
      title: {
        content: `${rule.name}`,
      },
      content: {
        content: `${rule.text}`,
      },
    }));
    setPanels(p);
  }

  function handleChange(e){
      const search = e.target.value.toUpperCase();
      let filteredRules = _.filter(allRules, function(r){
          return r.name.toUpperCase().includes(search);
      } )
      generatePanels(filteredRules);
  }
  
  return (
    <React.Fragment>
      <Helmet>
        <title>Rules Index</title>
        <meta name="Monster Room" content="Model rules, list builder and dice calculator for Monsterpocalypse." />
      </Helmet>

      <Container text className="rules-index-container animated fadeIn fast">
        <h2>Rules Index</h2>
        <p>To learn how to play Monsterpocalypse, <a href={LINKS.MONPOC_RULES} target="_blank">download the free rulebook.</a>
        </p>
        <p>Alphabetical listing of rules across all models.</p>

        <Input icon='search' placeholder='Search...' onChange={handleChange}/>
        <Accordion className="rules-accordion" exclusive={false} panels={panels} />
      </Container>
      
    </React.Fragment>
  )
};

export default withFirebase(RulesIndex);