export default function validate(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Name required';
  } 
  if (!values.uid) {
    errors.uid = 'UID required';
  }
  if (!values.agenda) {
    errors.agenda = 'Agenda required';
  }
  return errors;
};