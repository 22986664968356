import React, { useState, useEffect } from 'react';
import { Container, Grid, Divider } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import _ from 'lodash';

import AgendaList from '../../components/AgendaList';
import NewsFeed from '../../components/NewsFeed';
import EventsFeed from '../../components/EventsFeed';
import * as ROUTES from '../../constants/routes';
import './homepage.css';

function HomePage(props) {

  return (
    <div className="home-page-container">
      
      <AgendaList />
      <Container className="home-about home-announcements animated fadeIn">
        <Grid stackable divided="vertically" >
          <Grid.Row columns={2}>
            <Grid.Column className='home-col1'>
              <NewsFeed />
            </Grid.Column>
            <Grid.Column className='home-col2'>
              <h3>Other Links</h3>
              <div className="link-buttons">
                <Link to={ROUTES.DICE_ODDS}>
                  <div className="other-home-links bright-hover">
                    Dice Calculator
                  </div>
                </Link>
                <Link to={ROUTES.RULES_INDEX}>
                  <div className="other-home-links bright-hover">
                    Rules Index
                  </div>
                </Link>
              </div>
              <Divider />
              <div className="support-monster-room">
                <h3>Want to support Monster Room?</h3>
                <div className="kofi-button">
                  <a href='https://ko-fi.com/P5P5X8K8' target='_blank'>
                    <img height='36' style={{border: '0px', height: '36px'}} 
                      src='https://az743702.vo.msecnd.net/cdn/kofi1.png?v=2'
                      border='0' alt='Buy Me a Coffee at ko-fi.com' 
                    />
                  </a>
                </div>
              </div>
            </Grid.Column>
            {/*<Grid.Column className='home-col-3'>*/}
            {/*  <EventsFeed />*/}
            {/*</Grid.Column>*/}
          </Grid.Row>
          <Grid.Row>
            <div className="monpoc-disclaimer">
              <p>Monsterpocalypse is the property of Privateer Press. 
                Monster Room is not associated with Privateer Press. Please send bugs, 
                feedback and suggestions to <a className="bold-link" href = "mailto: admin@monsterroom.app">admin@monsterroom.app</a>.</p>
            </div>
          </Grid.Row>
        </Grid>
      </Container>


    </div>
  )
};

export default withFirebase(HomePage);