import React, { useContext, useState } from 'react';
import {Modal, Button, Icon, ButtonGroup, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import BuildingCard from '../StatCard/BuildingCard';
import  { ListContext } from '../ListBuilder/ListContext';

const ListItemBuilding = (props) =>{
  
  const [open, setOpen] = useState(false);   
  const {addBuilding, removeBuilding, list, getBuildingCountById} = useContext(ListContext);

  const {id, name, building, displayAdd, displayRemove, listCount} = props;

  // Logic for catalog display
  // todo refactor - very similar logic with only a minor difference, smarter way to do this
  let showCatalogCount = false;
  let count = getBuildingCountById(id);

  if(!listCount && list.isList){
    showCatalogCount = count > 0;
  } else if(listCount >= 2){
    showCatalogCount = true;
  }


  // catalog display and list display are similar but slightly different
  // const displayName = listCount >= 2 ? `${listCount}x ${name}` : name;
  const displayName =  name;

  // if list and agenda matches or isn't set yet
  let displayListControls = list.isList;

  return (
    <div className="generic-list-item">
      <div
        onClick={ () => setOpen(true)}
        key={id}
        className={`building-box model-list-item bright-hover animated fadeIn fast ${id}`}
      >
        <React.Fragment>
          <div className="item-label">
            <div className="item-name">
                {displayName}
            </div>
            {
              showCatalogCount &&
              <Label className="catalog-count-label" color="teal">
                  {count}
              </Label>
            }
          </div>
        </React.Fragment>
      </div>
      { displayListControls &&
      <Button.Group className="list-control-btn-grp">
        <Button className="list-control-add-btn" compact size="mini" icon onClick={() => addBuilding(building)}>
          <Icon name='plus' />
        </Button>
        <Button compact size="mini" icon disabled={count <= 0} onClick={() => removeBuilding(building)}>
          <Icon name='minus' />
        </Button>
      </Button.Group>
      }
      <Modal
        open={open}
        onClose={ () => setOpen(false)}
        closeIcon
        style={{'maxWidth': '700px', 'position': 'static'}}
      >
        <BuildingCard building={building}/>
      </Modal>
    </div>
  );
}

ListItemBuilding.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  building: PropTypes.object,
  displayAdd: PropTypes.bool,
  displayRemove: PropTypes.bool,
  listCount: PropTypes.number
}

ListItemBuilding.defaultProps = {
  id: null,
  name: null,
  building: {},
  displayAdd: false,
  displayRemove: false,
  listCount: null
}

export default withFirebase(ListItemBuilding);