import React, { Component } from 'react';
import { Container, Accordion, Icon, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import { Link, useParams } from "react-router-dom";
import MonsterList from '../MonsterList';
import UnitList from '../UnitList';
import BuildingList from '../BuildingList';
import AssetList from '../AssetList';
import DropItems from './drop-items';
import { ListContext } from "../ListBuilder/ListContext";

import './agendacatalog.css';

// todo rewrite with hooks might be better
// todo probably better to load both agendas, then filter based on match params
class AgendaCatalog extends Component {
  static contextType = ListContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      agendaID: 'null',
      selectedFactionID: null,
      factions: [], // populates dropdown
      expandMonsters: true,
      expandUnits: true,
      expandBuildings: true,
      expandAssets: true,
      isList: false // is list loaded?
  };

}

  componentDidMount() {
    // extract id from path
    const { agendaID } = this.props.match.params

    if(agendaID){
      this.setState({ loading: true });

      this.unsubscribe = this.props.firebase.factions()
      .where('agendaID', '==', agendaID)
      .onSnapshot(snapshot => {
        let factions = [];

        snapshot.forEach(doc =>
          factions.push({ ...doc.data(), uid: doc.id }),
        );

        this.setState({
          agendaID,
          factions,
          loading: false,
        });
      });
    }
  };

  // todo rewrite using hooks, on update load new agenda if needed
  componentDidUpdate(prevProps, prevState){
    const { agendaID } = this.props.match.params

    if( (prevState.agendaID !== agendaID) && !this.state.loading ){
      this.setState({ loading: true });

      this.unsubscribe = this.props.firebase.factions()
      .where('agendaID', '==', agendaID)
      .onSnapshot(snapshot => {
        let factions = [];

        snapshot.forEach(doc =>
          factions.push({ ...doc.data(), uid: doc.id }),
        );


        this.setState({
          agendaID,
          factions,
          loading: false,
          selectedFactionID: null, //reset any faction selection
        });
      });
    }
  }

  toggleExpandMonsters = () => {
    this.setState({
      expandMonsters: !this.state.expandMonsters
    });
  };
  toggleExpandUnits = () => {
    this.setState({
      expandUnits: !this.state.expandUnits
    });
  };
  toggleExpandBuildings = () => {
    this.setState({
      expandBuildings: !this.state.expandBuildings
    });
  };
  toggleExpandAssets = () => {
    this.setState({
      expandAssets: !this.state.expandAssets
    });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onSelection = (e) => {
    const val = e.target.value;
    let selection = val ==='all' ? null : val;
    this.setState({
      selectedFactionID: selection
    })
  };
      
  render() {
    const { factions, loading, selectedFactionID, agendaID } = this.state;
    
    const listContext = this.context;
    const { isList } = listContext.list;

    return (
        <div className="agenda-catalog">
          {
            loading ?
            <div>
              Loading...
            </div>
            :
            <React.Fragment>
            <div className="accordion-div">
              <div className="agenda-catalog-toolbar">
                <div>
                  <select className="faction-select ui search dropdown" onChange={this.onSelection}>
                    <option key='all' value='all'>All Factions</option>
                    <DropItems dropItems={factions} />
                  </select>
                </div>
                <div className="create-button-container">
                  {
                    !isList && 
                      <Button
                        className="create-list-button"
                        onClick={listContext.createNewList}
                        content="Create List"
                        icon="list"
                        color="blue"
                      />
                  }
                </div>
              </div>
            <Accordion exclusive={false} className="catalog-accordion animated fadeIn fast" fluid>
              <Accordion.Title onClick={this.toggleExpandMonsters} active={this.state.expandMonsters} >
                  <Icon name='dropdown' />
                  Monsters
                </Accordion.Title>
                <Accordion.Content active={this.state.expandMonsters}>
              <MonsterList
                agendaID={this.state.agendaID}
                selectedFactionID={selectedFactionID}
              />
              </Accordion.Content>

              <Accordion.Title onClick={this.toggleExpandUnits} active={this.state.expandUnits} >
                  <Icon name='dropdown' />
                  Units
                </Accordion.Title>
                <Accordion.Content active={this.state.expandUnits}>
              <UnitList
                agendaID={this.state.agendaID}
                selectedFactionID={selectedFactionID}
              />
              </Accordion.Content>

              <Accordion.Title onClick={this.toggleExpandBuildings} active={this.state.expandBuildings} >
                <Icon name='dropdown' />
                Buildings
              </Accordion.Title>
              <Accordion.Content active={this.state.expandBuildings}>
                <BuildingList />
              </Accordion.Content>

              <Accordion.Title onClick={this.toggleExpandAssets} active={this.state.expandAssets} >
                <Icon name='dropdown' />
                Assets
              </Accordion.Title>
              <Accordion.Content active={this.state.expandAssets}>
                <AssetList />
              </Accordion.Content>

            </Accordion>
            </div>
            </React.Fragment>
            
          }
      </div>
    );
  }
}

AgendaCatalog.propTypes = {

}

AgendaCatalog.defaultProps = {
}

export default withFirebase(AgendaCatalog);
