import React, { useState, useEffect } from 'react';
import validateList from './ListValidationRules';
import findIndex from 'lodash/findIndex';
import { getCount } from './Util';

const ListContext = React.createContext([{}, () => {}]);

const defaultListValues = {
  isList: false,
  agendaID: null, // protectors/destroyers
  monsters: [],
  units: [],
  buildings: []
};

// todo adding and remove would change a url paramater string
// url params [agenda]_[monsters]_[units]_[buildings]
// idea: use generatePath to then history push


// todo handle list loading
// run through params and generate list, indv calls by 2 digit param?

const ListContextProvider = (props) => {
  const [list, setList] = useState(defaultListValues);
  const [errors, setErrors] = useState([]);
  const [isCopied, setIsCopied] = useState(false);

  // Monster
  const addMonster = (monster) => {
    const monsters = list.monsters;
    // verify uid not in list already
    if(!monsters.some(m => {return m.uid === monster.uid}) ){
      monster.count = 1;
      monsters.push(monster);
      setList(list => ({...list, monsters}));
    }
    
  };

  const removeMonster = (monster) => {
    const monsters = list.monsters;
    const removeIndex = monsters.findIndex(m => m.uid === monster.uid);

    if(removeIndex !== -1) {
      if (monsters[removeIndex].count > 1) {
        monsters[removeIndex].count -= 1;
      } else {
        monsters.splice(removeIndex, 1);
      }

      setList(list => ({...list, monsters}));
    }
  };

  // Unit
  const addUnit = (unit) => {
    const units = list.units;
    const existsIndex = findIndex(units, (u) => { return u.uid === unit.uid;})

    if(existsIndex !== -1 ){
      // increase count
      units[existsIndex].count++;
      setList(list => ({...list, units}));
    } else {
      // else add to list with count of 1
      unit.count = 1;
      units.push(unit);
      setList(list => ({...list, units}));
    }
  };

  const removeUnit = (unit) => {
    const units = list.units;
    const removeIndex = units.findIndex(u => u.uid === unit.uid);

    if(removeIndex !== -1){
      if(units[removeIndex].count > 1){
        units[removeIndex].count -= 1;
      } else {
        units.splice(removeIndex, 1);
      }
      setList(list => ({...list, units}));
    }

  };

  // Building
  const addBuilding = (building) => {
    const buildings = list.buildings;
    const existsIndex = findIndex(buildings, (b) => { return b.uid === building.uid;})

    if(existsIndex !== -1 ){
      // increase count
      buildings[existsIndex].count++;
      setList(list => ({...list, buildings}));
    } else {
      // else add to list with count of 1
      building.count = 1;
      buildings.push(building);
      setList(list => ({...list, buildings}));
    }
  };

  const removeBuilding = (building) => {
    const buildings = list.buildings;
    const removeIndex = buildings.findIndex(b => b.uid === building.uid);

    if(removeIndex !== -1) {
      if (buildings[removeIndex].count > 1) {
        buildings[removeIndex].count -= 1;
      } else {
        buildings.splice(removeIndex, 1);
      }
      setList(list => ({...list, buildings}));
    }
  };

  // Create new & Clear are the same
  const createNewList = () => {
    const newList = {...list};
    newList.isList = true;
    newList.agendaID = null;
    newList.monsters = [];
    newList.units = [];
    newList.buildings = [];
    setList(newList);
  };


  // Get Counts by uid
  const getBuildingCountById = (uid) => {
    let count = 0;
    let obj = list.buildings.find(u => u.uid === uid );
    if(obj){
      count = obj.count;
    }
    return count
  };

  const getUnitCountById = (uid) => {
    let count = 0;
    let obj = list.units.find(u => u.uid === uid );
    if(obj){
      count = obj.count;
    }
    return count
  };

  // Cancel list building
  const stopListBuilding = () => {
    const newList = {...list};
    newList.isList = false;
    newList.agendaID = null;
    newList.monsters = [];
    newList.units = [];
    newList.buildings = [];
    setList(newList);
  };

  useEffect(() => {
    let { isList, agendaID, monsters, units, buildings } = list;
    
    if(!isList) {
      // check if isList should be true (items have been added)
      if(getCount(monsters) || getCount(units) || getCount(buildings)){
        isList = true;
        setList(list => ({...list, isList}));
      }
    }

    // check if agenda is known(based on first monster or unit added)
    if(!agendaID && (monsters.length > 0 || units.length > 0) ){
      if(getCount(monsters)){
        agendaID = monsters[0].agendaID;
        setList(list => ({...list, agendaID}));
      } else if (getCount(units)){
        agendaID = units[0].agendaID
        setList(list => ({...list, agendaID}));
      }  
    }

    // if no units or monsters, remove agenda
    if( agendaID && (monsters.length === 0 && units.length === 0)){
      agendaID = null;
      setList(list=> ({...list, agendaID}))
    }

    // if list matched clipboard, than is changed
    if (isCopied){ 
      setIsCopied(false);
    }

    setErrors(validateList(list));
  }, [list]);

  return (
    <ListContext.Provider 
      value={{list,
        setList,
        errors,
        addMonster,
        removeMonster,
        addUnit,
        removeUnit,
        addBuilding,
        removeBuilding,
        createNewList,
        stopListBuilding,
        setIsCopied,
        getUnitCountById,
        getBuildingCountById
      }}
    >
      {props.children}
    </ListContext.Provider>
  );
}

export { ListContext, ListContextProvider };