import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Modal } from 'semantic-ui-react';
import { withFirebase } from '../../Firebase';
import useForm from '../useForm';
import validate from './EnterAttackValidationRules';

import SelectRuleForm from '../SelectRuleForm';
import CardRule from '../../StatCard/CardRule/CardRule';

import './enterAttackForm.css';

function EnterAttackForm(props) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(returnAttack, validate);

  const [ruleModalOpen, setRulesModal] = useState(false);
  const [displayRules, setDisplayRules] = useState([]);


  function returnAttack() {
    if(!values.range){
      values.range='0'; //set to 0 by default
    }

    const attack = {
      type: values.type,
      range: values.range,
      aDice: values.actionDice,
      bDice: values.boostDice,
      pDice: values.powerDice,
      rulesById: values.rulesById
    }
    // save to parent
    props.onAttackSaved(attack);
  }

  function addRule(rule){
    setRulesModal(!ruleModalOpen);
    values.rulesById.push(rule.uid);
    generateDisplayRules(values.rulesById);
  }

  function removeRule(ruleID){
    const index = values.rulesById.indexOf(ruleID); //get array index of rule
    values.rulesById.splice(index, 1); //remove rule
    generateDisplayRules(values.rulesById);
  }

  // generate rule html for display
  function generateDisplayRules(arr){
    const rulesArr = values.rulesById.map((rule,i) =>
      <div className="display-with-remove-button" key={i}>
        <CardRule className="fill-remaining-space" ruleID={rule}/>
        <Button color="red" type="button" className="remove-button" onClick={() => removeRule(rule)}>Remove</Button>
    </div>
    );
    setDisplayRules(rulesArr);
  }

  useEffect(() => {
    values.rulesById = [];
    values.powerDice = '0'; //default to 0
  }, []); // empty array as 2nd arg means this will only run on mount


  return (
    <div className="form-container">
      <Form onSubmit={handleSubmit} noValidate error>
      <Button color="green" type="submit" className="button">Save Attack</Button>
        
       <Form.Group> 
        {/* semantic-ui-react select doesnt work */}
        <Form.Field>
          <label>Type</label>
          <select className="ui selection dropdown" name="type" value={values.type} onChange={handleChange}>
            <option value=''>Select type...</option>
            <option value='brawl'>Brawl</option>
            <option value='blast'>Blast</option>
            <option value='power'>Power</option>
          </select>
          {errors.type &&
            <Message error content={errors.type} />
          }
        </Form.Field>
        <Form.Field disabled={ values.type !== 'blast' }>
        <label>Range</label>
          <Form.Input 
            type='text' name='range'
            onChange={handleChange}
            value={values.range || ''}
            required 
          />
          {errors.range &&
            <Message error content={errors.range} />
          }
        </Form.Field>
      </Form.Group>
            
      <Form.Group>
        <Form.Field>
          <label>Action Dice</label>
            <Form.Input 
              type='actionDice' name='actionDice'
              onChange={handleChange}
              value={values.actionDice || ''}
              required 
            />
          {errors.actionDice &&
            <Message error content={errors.actionDice} />
          }
        </Form.Field>
      
        <Form.Field>
          <label>Boost Dice</label>
            <Form.Input 
              type='boostDice' name='boostDice'
              onChange={handleChange}
              value={values.boostDice || ''}
              required
            />
            {errors.boostDice &&
            <Message error content={errors.boostDice} />
            }
        </Form.Field>
      
        <Form.Field>
        <label>Power Dice</label>
            <Form.Input 
              type='text' name='powerDice'
              onChange={handleChange}
              value={values.powerDice || '0'}
              required 
            />
            {errors.powerDice &&
            <Message error content={errors.powerDice} />
            }
        </Form.Field>
      </Form.Group>
          <div>
            {displayRules}
          </div>
      </Form>

      <Button color="blue" type="button" className="add-rule-button" onClick={() => setRulesModal(!ruleModalOpen)}>Add Rule</Button>
        <Modal
          open={ruleModalOpen}
          onClose={() => setRulesModal(!ruleModalOpen)}
          center
          closeIcon
        >
            <SelectRuleForm onRuleSubmit={addRule}/>
        </Modal>
    </div>
  );
};

export default withFirebase(EnterAttackForm);

