import React, { useContext, useState } from 'react';
import { Modal, Button, Icon, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import MonsterCard from '../StatCard/MonsterCard';

import  { ListContext } from '../ListBuilder/ListContext';

const ListItemMonster = (props) => {

  const [open, setOpen] = useState(false); 
  const {addMonster, removeMonster, list} = useContext(ListContext);

  const {id, name, monster, displayAdd, displayRemove, agendaID, isListView} = props;

  // if list and agenda matches or isn't set yet
  let displayListControls = list.isList && 
    (list.agendaID === agendaID || !list.agendaID );

  const listMonsters = list.monsters;
  let alreadyIn = false;
  // if list
  if (list.isList){
    // check if monster already in list
    listMonsters.forEach(monster => {
      if (monster.uid === id){
        alreadyIn = true;
      }
    })

  }

  return (
    <div className="generic-list-item">
      <div
        onClick={ () => setOpen(true)}
        key={id}
        className={`monster-box model-list-item bright-hover animated fadeIn fast ${id}`}
      >
        <div className="item-label">
          <div className="item-name">
            {name}
          </div>
          {
            (alreadyIn && !isListView) &&
            <Icon inverted color='black' name='check' />
          }
        </div>
      </div>
      {displayListControls &&
      <Button.Group className="list-control-btn-grp">
        <Button className="list-control-add-btn" compact size="mini" icon disabled={alreadyIn} onClick={() => addMonster(monster)}>
          <Icon name='plus'/>
        </Button>
        <Button compact size="mini" icon disabled={!alreadyIn} onClick={() => removeMonster(monster)}>
          <Icon name='minus'/>
        </Button>
      </Button.Group>
      }

      <Modal 
        open={open}
        onClose={ () => setOpen(false)}
        closeIcon
        style={{'maxWidth': '700px', 'position': 'static'}}
      >
        <MonsterCard monster={monster}/>
      </Modal>
    </div>
  );
};

ListItemMonster.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  monster: PropTypes.object,
  displayAdd: PropTypes.bool,
  displayRemove: PropTypes.bool,
  agendaID: PropTypes.string,
  isListView: PropTypes.bool
}

ListItemMonster.defaultProps = {
  id: null,
  name: null,
  monster: {},
  displayAdd: false,
  displayRemove: false,
  agendaID: '',
  isListView: false
}

export default withFirebase(ListItemMonster);