import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Label, Dimmer, Loader, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { withFirebase } from '../../Firebase';
import useForm from '../useForm';
import validate from './UnitFormValidationRules';

import ModelDetails from '../ModelDetails';

import './unitForm.css';

function UnitForm(props) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submitUnit, validate);
  // submitMonster is the callback after validate is successful

  //if editing prevent uid from changing
  const [editing, setEditing] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [factions, setFactions] = useState([]);
  const [detailsReady, setDetailsReady] = useState(false);
  const [savedSuccess, setSavedSucess] = useState(null);
  const [elite, setElite] = useState(false);

  function isDetailsReady(bool){
    setDetailsReady(bool);
  }

  function submitUnit() {
    const fIndex = factions.findIndex(faction => faction.uid === values.factionID); 
    const agendaID = factions[fIndex].agendaID;
    
    props.firebase.units().doc(values.uid).set({
      name: values.name,
      factionID: values.factionID,
      agendaID: agendaID,
      cost: values.cost,
      elite: elite,
      attacks: values.details.attacks,
      rulesById: values.details.rulesById,
      spd: values.details.spd,
      def: values.details.def,
      highMobility: values.details.highMobility
    })
    .then(function() {
      setSavedSucess(true);
    })
    .catch(function(error){
      console.log("Error saving ", error)
      setSavedSucess(false); 
    })
  }

  useEffect(() => {
    // if editing 
    if ( props.match.params.id ){
      const editID = props.match.params.id;
      setLoading(true);
      setEditing(true);
      // user doesnt have to save details
      setDetailsReady(true);

      props.firebase.unit(editID)
      .onSnapshot(snapshot => {
        const entry = snapshot.data();         
        // set default values
        values.uid = editID;
        values.name = _.get(entry, 'name');
        values.factionID = _.get(entry, 'factionID');
        values.cost = _.get(entry, 'cost');
        if( _.get(entry, 'elite')){
          setElite(true);
        };
        // initial details
        values.details = {};
        values.details.def = _.get(entry, 'def');
        values.details.spd = _.get(entry, 'spd');
        values.details.attacks = _.get(entry, 'attacks');
        values.details.rulesById = _.get(entry, 'rulesById');
        values.details.highMobility = _.get(entry, 'highMobility');
        setLoading(false);
      });
    }

    //populate faction dropdown
    props.firebase.factions()
      .onSnapshot(snapshot => {
        let factions = [];

        snapshot.forEach(doc =>
            factions.push({ ...doc.data(), uid: doc.id }),
        );
        setFactions(factions);
      });
  }, []); // empty array as 2nd arg means this will only run on mount

  function onDetailsSubmit(vals){
    values.details = vals;
  }

  function toogleElite(){
    setElite(!elite);
  }

  return (
    <div className="form-container">
    {
      loading ?
      <Segment>
        <Dimmer active inverted>
          <Loader/>
        </Dimmer>
      </Segment>
      :
      <React.Fragment>
      <Message
        hidden={!savedSuccess}
        icon={savedSuccess ? 'check circle' : 'exclamation circle'}
        content={savedSuccess ? 'Save successful' : 'Did not save!'}
      />
      <Form className="general-details" onSubmit={handleSubmit} noValidate error>
        <Button color="green" type="submit" className="button" disabled={!detailsReady}>Submit Unit</Button>
        {
          !detailsReady &&
          <Label basic color='red'>Details Not Ready</Label>
        }
 
        <Form.Group>
          <Form.Field>
          <label>Unit Name</label>
            <Form.Input type="text" name="name" onChange={handleChange} value={values.name || ''} required />
            {errors.name &&
              <Message error content={errors.name} />
            }
        </Form.Field>
        <Form.Field>
          <label>UID</label>
            <Form.Input disabled={editing} type="text" name="uid" onChange={handleChange} value={values.uid || ''} required />
            {errors.uid &&
              <Message error content={errors.uid} />
            }
          </Form.Field>
        
          <Form.Field>
          <label>Faction</label>
            <select
              className="ui search dropdown"
              name="factionID"
              onChange={handleChange}
              value={values.factionID || ''}
            >
              <option key={0} >Choose Faction</option>
              {factions.map(faction =>
                  <option key={faction.uid} value={faction.uid}>{faction.name}</option>
              )};
            </select>
            {errors.factionID &&
              <Message error content={errors.factionID} />
            }
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Cost</label>
              <Form.Input type="text" name="cost" onChange={handleChange} value={values.cost || ''} required />
              {errors.cost &&
                <Message error content={errors.cost} />
              }
            </Form.Field>
            <Form.Field>
                <Form.Checkbox name="elite" checked={elite} onClick={toogleElite} label="Elite"/>
            </Form.Field>
          </Form.Group>
        </Form>
      <hr />
      <div> 
        <ModelDetails
          form="unit"
          isReady={isDetailsReady}
          onSubmit={onDetailsSubmit}
          initialDetails={values.details}
        />
      </div>
      </React.Fragment>
    }
  </div>
  );
};

export default withFirebase(UnitForm);

